import { Table } from '@tanstack/react-table';
import { Icon } from 'components/Icon';
import Typography from 'components/Typography/Typography';
import styles from './TablePaginator.module.scss';

const TablePaginator = <T extends object>({ table }: { table: Table<T> }) => {
  const page = table.getState().pagination.pageIndex;
  const itemsPerPage = table.getState().pagination.pageSize;
  const maxPage = table.getPageCount();
  const totalItems = table.getRowCount();
  return (
    <>
      {maxPage >= 1 && (
        <div className={styles.tablePaginatorWrapper}>
          <div className={styles.itemsPerPageSelect}>
            <select
              onChange={e =>
                table.setPageSize(e.target.value as unknown as number)
              }
              value={itemsPerPage}
            >
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
            <Typography variant='label' className='my-auto'>
              Items per page
            </Typography>
          </div>
          <div className='flex'>
            <Typography variant='label' className='my-auto'>
              {`Showing ${itemsPerPage * page + 1}-${
                itemsPerPage * (page + 1) > totalItems
                  ? totalItems
                  : itemsPerPage * (page + 1)
              } of ${totalItems}`}
            </Typography>
            <button
              className={styles.paginateBack}
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.firstPage()}
            >
              <Icon name='skipBack' />
            </button>
            <button
              className={styles.paginateBack}
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
            >
              <Icon name='chevronBack' />
            </button>
            <button
              className={styles.paginateForward}
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
            >
              <Icon name='chevronForward' />
            </button>
            <button
              className={styles.paginateForward}
              disabled={!table.getCanNextPage()}
              onClick={() => table.lastPage()}
            >
              <Icon name='skipForward' />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TablePaginator;
