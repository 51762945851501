import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { parseDate } from 'helpers';
import useFeature from 'services/features/useFeature';
import useSurveyResponses from 'services/surveys/surveyResponses';
import useProfileSurveys from 'services/surveys/useProfileSurveys';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getSubmittedDate } from '../SurveysSummary/SurveysSummary';
import { getSurveysStatusForKitId } from 'store/kits';
import { SurveyResponsesType } from 'types/Surveys';

import {
  Icon,
  Header,
  PageContainer,
  QuestionAnswerList,
  Card,
  SectionPlaceholder,
  Spinfinity,
  Typography,
  LinkButton,
  ButtonVariant,
  ButtonSize,
  ButtonColor,
} from 'components';
import { SurveyCard } from '../SurveyCard/SurveyCard';
import { TypeformSurveyCard } from '../TypeformSurveyCard/TypeformSurveyCard';
import { ProfileAnswersCard } from '../ProfileAnswersCard/ProfileAnswersCard';
import { SampleInfoAnswersCard } from '../SampleInfoAnswersCard/SampleInfoAnswersCard';
import {
  ProfileQuestionsAndAnswers,
  ProfileVariant,
} from '../ProfileQuestionsAndAnswers/ProfileQuestionsAndAnswers';

import styles from './SurveysDetail.module.scss';

export const SurveysDetail = () => {
  const { currentKitId, currentTinyAccountId } = useCurrentKitAndTinyAccount();
  const { isFeatureEnabled: showProfiles } = useFeature('profiles');
  const { isNewProfileFlow } = useProfileSurveys(
    currentTinyAccountId,
    currentKitId,
  );
  return showProfiles && isNewProfileFlow ? (
    <SurveysDetailWithProfile />
  ) : (
    <LegacySurveysDetail />
  );
};

const SurveysDetailWithProfile = () => {
  const { currentKit } = useCurrentKitAndTinyAccount();
  const { formId } = useParams<{ kitId: string; formId: string }>();
  const { currentKitId, currentTinyAccount, currentTinyAccountId } =
    useCurrentKitAndTinyAccount();
  //surveys check
  const { data: surveys } = useProfileSurveys(
    currentTinyAccountId,
    currentKitId,
  );
  const currentSurvey = useMemo(() => {
    return !surveys?.length
      ? null
      : surveys.find(survey => survey.typeform_survey_id === formId);
  }, [surveys, formId]);
  const otherSurveysNeeded = useMemo(() => {
    return !surveys?.length
      ? []
      : surveys.filter(survey => survey.typeform_survey_id !== formId);
  }, [formId, surveys]);

  //survey responses
  const {
    data: surveyResponses,
    kitBelongsToAccount,
    isLoading,
  } = useSurveyResponses(currentKitId, true);
  const currentSurveyResponses = useMemo(() => {
    if (surveyResponses) {
      return surveyResponses.find(
        (survey: SurveyResponsesType) => survey.form_id === formId,
      );
    } else {
      return null;
    }
  }, [surveyResponses, formId]);

  const surveyName = useMemo(() => {
    if (formId === 'profile') return 'Profile questions';
    if (formId === 'sample') return 'Sample questions';
    return currentSurvey?.name ?? 'Survey';
  }, [currentSurvey, formId]);

  if (!kitBelongsToAccount) {
    return (
      <>
        <Header title={currentSurvey?.name ?? 'Survey'} />
        <PageContainer>
          <div className={styles.submittedTab}></div>
          <main className={styles.mainContent}>
            <SectionPlaceholder
              title={`No access to kit ${currentKitId} under this account`}
            />
          </main>
        </PageContainer>
      </>
    );
  }
  if (!currentKitId || !currentTinyAccount) {
    return null;
  }
  return (
    <>
      <Header title={surveyName} />
      <PageContainer>
        <div className={styles.submittedTab}>
          {currentSurveyResponses?.submitted_at && (
            <>
              <Icon name='calendarFilled' size='xs' />
              <span>
                Submitted on{' '}
                {format(
                  parseDate(currentSurveyResponses.submitted_at.slice(0, 10)), //use only date
                  'MM/dd/yy',
                )}
              </span>
            </>
          )}
        </div>
        <div className={styles.sectionsWrapper}>
          <main className={styles.mainContent}>
            {isLoading ? (
              <Spinfinity />
            ) : formId && ['profile', 'sample'].includes(formId) ? (
              <ProfileQuestionsAndAnswers variant={formId as ProfileVariant} />
            ) : currentSurveyResponses?.hide_responses ||
              !currentSurveyResponses?.questions?.length ? (
              <SectionPlaceholder title='Your responses are saved. It may take up to 5 minutes for them to appear in our system. Thanks for your patience!' />
            ) : (
              <Card>
                <QuestionAnswerList list={currentSurveyResponses.questions} />
              </Card>
            )}
          </main>
          {!!otherSurveysNeeded?.length &&
            !!surveyResponses?.length &&
            !currentKit?.partner_id && (
              <section>
                <div className={styles.sectionTitle}>Other surveys</div>
                <div className={styles.cardsContainer}>
                  {formId === 'profile' && <SampleInfoAnswersCard />}
                  {formId === 'sample' && <ProfileAnswersCard />}
                  {!['profile', 'sample'].includes(formId ?? '') && (
                    <>
                      <ProfileAnswersCard />
                      <SampleInfoAnswersCard />
                    </>
                  )}
                  {otherSurveysNeeded
                    ?.filter(
                      survey =>
                        !survey.is_partner,
                    )
                    .map(surveyType => (
                      <TypeformSurveyCard
                        key={surveyType.typeform_survey_id}
                        linkDetail={`/results/${currentKitId}/surveys/${surveyType.typeform_survey_id}`}
                        linkTypeform={`/typeform/${currentTinyAccountId}/${currentKitId}/${surveyType.typeform_survey_id}`}
                        needed={!surveyType.is_complete}
                        dateSubmitted={getSubmittedDate(
                          surveyResponses,
                          surveyType.typeform_survey_id,
                        )}
                        estimated_time={surveyType.time_estimate}
                        typeform_id={surveyType.typeform_survey_id}
                        display_title={surveyType?.name ?? 'Survey'}
                      />
                    ))}
                  <SurveyChangesCard />
                </div>
              </section>
            )}
        </div>
      </PageContainer>
    </>
  );
};

const LegacySurveysDetail = () => {
  const { formId } = useParams<{ kitId: string; formId: string }>();
  const { currentKit, currentKitId, currentTinyAccount } =
    useCurrentKitAndTinyAccount();
  //surveys check
  const status = useSelector(getSurveysStatusForKitId(currentKitId));
  const otherSurveysNeeded = useMemo(() => {
    return !status?.needed?.length
      ? []
      : status.needed.filter(survey => survey.typeform_id !== formId);
  }, [formId, status]);
  const pendingSurveys = status?.pending.map(survey => survey.typeform_id);
  const currentSurveyType = status?.needed.find(
    survey => survey.typeform_id === formId,
  );

  //survey responses
  const {
    data: surveyResponses,
    kitBelongsToAccount,
    isLoading,
  } = useSurveyResponses(currentKitId);
  const currentSurveyResponses = useMemo(() => {
    if (surveyResponses) {
      return surveyResponses.find(
        (survey: SurveyResponsesType) => survey.form_id === formId,
      );
    } else {
      return null;
    }
  }, [surveyResponses, formId]);

  if (!kitBelongsToAccount) {
    return (
      <>
        <Header title={currentSurveyType?.display_title ?? 'Survey'} />
        <PageContainer>
          <div className={styles.submittedTab}></div>
          <main className={styles.mainContent}>
            <SectionPlaceholder
              title={`No access to kit ${currentKitId} under this account`}
            />
          </main>
        </PageContainer>
      </>
    );
  }
  if (!currentKitId || !currentTinyAccount) {
    return null;
  }
  return (
    <>
      <Header title={currentSurveyType?.display_title ?? 'Survey'} />
      <PageContainer>
        <div className={styles.submittedTab}>
          {currentSurveyResponses?.submitted_at && (
            <>
              <Icon name='calendarFilled' size='xs' />
              <span>
                Submitted on{' '}
                {format(
                  parseDate(currentSurveyResponses.submitted_at.slice(0, 10)), //use only date
                  'MM/dd/yy',
                )}
              </span>
            </>
          )}
        </div>
        <div className={styles.sectionsWrapper}>
          <main className={styles.mainContent}>
            {isLoading ? (
              <Spinfinity />
            ) : currentSurveyResponses?.hide_responses ||
              !currentSurveyResponses?.questions?.length ? (
              <SectionPlaceholder title='Your responses are saved. It may take up to 5 minutes for them to appear in our system. Thanks for your patience!' />
            ) : (
              <Card>
                <QuestionAnswerList list={currentSurveyResponses.questions} />
              </Card>
            )}
          </main>
          {!!otherSurveysNeeded?.length &&
            !!surveyResponses?.length &&
            !currentKit?.partner_id && (
              <section>
                <div className={styles.sectionTitle}>Other surveys</div>
                <div className={styles.cardsContainer}>
                  {otherSurveysNeeded?.map(surveyType => (
                    <SurveyCard
                      key={surveyType.typeform_id}
                      {...surveyType}
                      kit={currentKit}
                      tinyAccount={currentTinyAccount}
                      linkDetail={`/results/${currentKitId}/surveys/${surveyType.typeform_id}`}
                      needed={pendingSurveys?.includes(surveyType.typeform_id)}
                      dateSubmitted={getSubmittedDate(
                        surveyResponses,
                        surveyType.typeform_id,
                      )}
                    />
                  ))}
                  <SurveyChangesCard />
                </div>
              </section>
            )}
        </div>
      </PageContainer>
    </>
  );
};

export const SurveyChangesCard = () => {
  return (
    <Card>
      <Typography variant='body-s'>
        If you need to make any changes to your survey responses, please contact
        us at{' '}
        <LinkButton
          label='hello@tinyhealth.com'
          href='mailto:hello@tinyhealth.com'
          external
          variant={ButtonVariant.TEXT}
          size={ButtonSize.M}
          color={ButtonColor.PURPLE}
        />{' '}
        and we will manually update the information for you.
      </Typography>
    </Card>
  );
};
