/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// ^ React table typing is weird
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash-es';
import c from 'classnames';
import { useForm } from 'react-hook-form';
import {
  Column,
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowData,
  SortingFn,
  sortingFns,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import type {
  ColumnFilter,
  ColumnDef,
  FilterFns,
  ColumnMeta,
  FilterMeta,
} from '@tanstack/react-table';
import {
  compareItems,
  RankingInfo,
  rankItem,
} from '@tanstack/match-sorter-utils';

import THField from 'components/THField/THField';

import TablePaginator from 'components/Table/TablePaginator';

import styles from './Table.module.scss';
import { Spinfinity } from 'components/Spinners';

import { Icon } from 'components/Icon';
import { getColumnFilters, setColumnFilters } from 'store/table';

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T, string | undefined>[];
  onSubmitSearchForm?: (searchValue: string) => Promise<void>;
  page?: number;
  maxPage?: number;
  totalItems?: number;
  itemsPerPage?: number;
  onSetPage?: (step: number) => void;
  isLoading?: boolean;
  hideSearch?: boolean;
  NullStateComponent?: React.ReactNode;
}
declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select';
    FilterComponent: React.FC<{
      column: Column<TData>;
    }>;
  }
}
type SearchFormField = {
  searchString: string;
};
export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;
  // Only sort by rank if the column has ranking information
  if (
    rowA.columnFiltersMeta?.[columnId] &&
    rowB.columnFiltersMeta?.[columnId]
  ) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]!.itemRank,
      rowB.columnFiltersMeta[columnId]!.itemRank,
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};
const Table = <T extends object>({
  data,
  columns,
  onSubmitSearchForm,
  isLoading,
  page,
  maxPage,
  totalItems,
  itemsPerPage,
  onSetPage,
  hideSearch,
  NullStateComponent,
}: ReactTableProps<T>) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, watch } = useForm<SearchFormField>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const searchStringField = register('searchString');
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const columnFilters = useSelector(getColumnFilters);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: page ?? 0,
    pageSize: itemsPerPage ?? 15,
  });
  const [showFilterComponentState, setShowFilterComponentState] = useState<{
    [key: string]: boolean;
  }>({});
  const submitSearchForm = useCallback(
    async (formData: SearchFormField) => {
      if (onSubmitSearchForm) {
        await onSubmitSearchForm(formData.searchString.trim());
      } else {
        setGlobalFilter(formData.searchString.trim());
      }
    },
    [onSubmitSearchForm, setGlobalFilter],
  );
  const setColumnFiltersState: OnChangeFn<ColumnFiltersState> = async (
    incomingColumnFilters: Updater<ColumnFilter[]>,
  ) => {
    const newColumnFilters: ColumnFilter[] = [];
    if (incomingColumnFilters instanceof Function) {
      for (const filter of incomingColumnFilters(columnFilters ?? [])) {
        if (filter.value) {
          newColumnFilters.push(filter);
        }
      }
    } else if (Array.isArray(incomingColumnFilters)) {
      for (const filter of incomingColumnFilters) {
        if (filter.value) {
          newColumnFilters.push(filter);
        }
      }
    }
    await dispatch(setColumnFilters(newColumnFilters));
    return columnFilters;
  };
  useEffect(() => {
    const debouncedSubmit = debounce(
      () => handleSubmit(submitSearchForm)(),
      500,
    );
    const subscription = watch(debouncedSubmit);
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, submitSearchForm]);

  const fuzzyFilter: FilterFn<T> = useCallback(
    (row, columnId, value, addMeta) => {
      // Rank the item
      const itemRank = rankItem(row.getValue(columnId), value);
      // Store the itemRank info (for potential use in sorting)
      addMeta({
        itemRank,
      });

      // Return if the item should be filtered in/out
      return itemRank.passed;
    },
    [],
  );
  const inclusiveFilter: FilterFn<T> = useCallback((row, columnId, value) => {
    const rowValue = row.getValue(columnId) as any;
    if (Array.isArray(rowValue)) {
      return rowValue.includes(value);
    } else if (typeof rowValue === 'string') {
      return rowValue.toLowerCase().includes(value.toLowerCase());
    } else if (typeof rowValue === 'number') {
      return rowValue === value;
    }
    return false;
  }, []);

  const table = useReactTable({
    data,
    columns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    defaultColumn: {
      minSize: 0,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnFiltersChange: columnFilters =>
      setColumnFiltersState(columnFilters),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      globalFilter,
      columnFilters,
      pagination,
      sorting,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: inclusiveFilter,
  });
  const handleFilterClick = (column: Column<T>) => {
    setShowFilterComponentState({
      ...showFilterComponentState,
      [column.id]: !showFilterComponentState[column.id],
    });
  };
  const closeAllFilters = () => {
    setShowFilterComponentState({});
  };
  return (
    <>
      {!hideSearch && (
        <>
          <form
            className={styles.authForm}
            onSubmit={handleSubmit(submitSearchForm)}
          >
            <div className={styles.searchRow}>
              <div className={styles.searchInputContainer}>
                <THField
                  type='text'
                  label='Search'
                  classes={styles.searchInput}
                  labelClasses='invisible'
                  placeholder='Search'
                  icon='search'
                  hideErrors
                  {...searchStringField}
                />
              </div>
            </div>
          </form>
        </>
      )}
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead className={styles.header}>
            {!hideSearch &&
              table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      className={styles.headerCell}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <div className={styles.headerCellContent}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {header.column.getCanSort() && (
                            <div className={styles.sortButtons}>
                              <button
                                onClick={header.column.getToggleSortingHandler()}
                                className={c(
                                  styles.sortButton,
                                  header.column.getIsSorted() === 'asc' &&
                                    styles.sortButtonActive,
                                )}
                              >
                                <Icon name='chevronUp' size='s' />
                              </button>
                              <button
                                onClick={header.column.getToggleSortingHandler()}
                                className={c(
                                  styles.sortButton,
                                  header.column.getIsSorted() === 'desc' &&
                                    styles.sortButtonActive,
                                )}
                              >
                                <Icon name='chevronDown' size='s' />
                              </button>
                            </div>
                          )}
                          {header.column.getCanFilter() && (
                            <>
                              <div
                                className={styles.filterButton}
                                onClick={() => handleFilterClick(header.column)}
                              >
                                <Icon name='filterLines' size='s' />
                              </div>
                              {showFilterComponentState[header.column.id] &&
                              header.column.columnDef.meta?.FilterComponent ? (
                                <Suspense fallback={<Spinfinity />}>
                                  <header.column.columnDef.meta.FilterComponent
                                    column={header.column}
                                  />
                                </Suspense>
                              ) : null}
                              {showFilterComponentState[header.column.id] && (
                                <div
                                  className={styles.filterOverlay}
                                  onClick={closeAllFilters}
                                ></div>
                              )}
                            </>
                          )}
                          {header.column.getCanResize() && (
                            <div
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              className={c(
                                styles.resizer,
                                header.column.getIsResizing()
                                  ? styles.isResizing
                                  : '',
                              )}
                            ></div>
                          )}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody>
            {isLoading && (
              <div className='my-auto'>
                <Spinfinity />
              </div>
            )}
            {!isLoading && (
              <>
                {/* If there are no rows, show null state component from props*/}
                {table.getRowModel().rows.length === 0 && (
                  <tr>{NullStateComponent}</tr>
                )}
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id} className={styles.tr}>
                    {row.getVisibleCells().map(cell => (
                      <td
                        className={styles.td}
                        style={{
                          width: cell.column.getSize(),
                          maxWidth: cell.column.getSize(),
                        }}
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      <TablePaginator table={table} />
    </>
  );
};

export default Table;
