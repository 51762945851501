import { LINKS } from 'assets';
import c from 'classnames';

import useCheckoutKit from 'hooks/useCheckoutKit';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import { KitType } from 'types/Kit';

import { Card, DesignButton, Typography } from 'components';
import {
  ButtonColor,
  ButtonColorType,
  ButtonSize,
} from 'components/DesignButton/DesignButton';
import { EventPropertiesMap } from 'contexts/analytics/types';

import styles from './CTASection.module.scss';

type CTACardProps = {
  title: string;
  backgroundClass: string;
  clickable: EventPropertiesMap['Clicked Button'];
  buttonColor?: ButtonColorType;
  ctaText: string;
};
const CTA_CARDS: {
  [key: string]: CTACardProps;
} = {
  membership: {
    title: 'What’s next?',
    backgroundClass: styles.membership,
    clickable: {
      href: LINKS.membership,
      text: 'Explore Membership',
      isExternal: true,
    },
    buttonColor: 'purple',
    ctaText: 'Unlock exclusive membership benefits and save with Tiny+',
  },
  order: {
    title: 'Test again',
    backgroundClass: styles.order,
    clickable: {
      href: LINKS.store,
      text: 'Order Kit',
      isExternal: true,
    },
    ctaText: 'Track your progress with your next kit',
  },
};
const getCTACards = (
  isAtTheLastStep: boolean,
  hasMembership: boolean,
  kitType?: KitType,
) => {
  const { checkoutUrl } = useCheckoutKit(kitType);
  const ctaCards = [];
  if (!hasMembership && isAtTheLastStep) {
    ctaCards.push(CTA_CARDS.membership);
    //adjust kit price and store link
    let updatedOrderCard = { ...CTA_CARDS.order };
    updatedOrderCard.clickable.href = checkoutUrl;
    ctaCards.push(updatedOrderCard);
  }
  return ctaCards;
};

const CTACard = ({
  title,
  backgroundClass,
  clickable,
  buttonColor,
  ctaText,
}: CTACardProps) => {
  return (
    <Card className={c(styles.ctaCard, backgroundClass)} clickable={clickable}>
      <Typography variant='body-s' className={styles.ctaTitle}>
        {title}
      </Typography>
      <Typography variant='body-m' className={styles.ctaText}>
        {ctaText}
      </Typography>
      <DesignButton
        label={clickable.text}
        color={buttonColor || ButtonColor.LIGHT}
        className={styles.ctaButton}
        size={ButtonSize.M}
        fullWidth
      />
    </Card>
  );
};

const CTASection = ({ tinyAccountId }: { tinyAccountId: string | null }) => {
  const { latestKit, hasMembership } = useTinyAccountForHome(tinyAccountId);
  const isAtTheLastStep = !!latestKit?.date_actions_step_clicked;
  const ctaCards = getCTACards(
    isAtTheLastStep,
    hasMembership,
    latestKit?.sampling_type,
  );
  if (!ctaCards.length) return null;
  return (
    <div className={c(styles.container, 'hide-on-desktop')}>
      {ctaCards.map((card, index) => {
        return <CTACard key={index} {...card} />;
      })}
    </div>
  );
};

export default CTASection;
