import { useSelector } from 'react-redux';
import { getConsultCreditsCount, getIsAnyTinyPlus } from 'store/account';
import { Typography } from 'components';

export const ConsultPricing = () => {
  const consultCreditsCount = useSelector(getConsultCreditsCount);
  const isAnyTinyPlus = useSelector(getIsAnyTinyPlus);
  const isFullPrice = !isAnyTinyPlus;

  return consultCreditsCount > 0 ? (
    <div className='text-center'>
      <Typography variant='label'>{`You have ${consultCreditsCount} free call${
        consultCreditsCount === 1 ? '' : 's'
      } remaining.`}</Typography>
    </div>
  ) : (
    <div className='text-center'>
      <Typography variant='label'>Calls are </Typography>
      {!isFullPrice && (
        <Typography variant='label' className='line-through'>
          $100
        </Typography>
      )}
      <Typography variant='label'>
        {isFullPrice ? `$100` : ` $39`} per 20 minute call.
      </Typography>
    </div>
  );
};
