import { useEffect, useRef } from 'react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import useFeature from 'services/features/useFeature';
import { getSubMetricDetailQuery } from 'services/insights/useSubMetricDetail';
import { getTaxaOverTimeQuery } from 'services/insights/useTaxaOverTime';
import { getActionPlanItemsQuery } from 'services/actionPlan/actionPlanData';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import { InsightMetric } from 'types/Insights';
import { ActionPlanItem } from 'types/ActionPlan';

import {
  PageHeader,
  ContainerSizes,
  PageContainer,
  Typography,
  Icon,
  LinkButton,
  ButtonSize,
  ButtonColor,
} from 'components';
import { ActionPlanGroup } from 'views/ActionPlan/ActionPlanGroup/ActionPlanGroup';
import { ActionPlanItemCard } from 'views/ActionPlan/ActionPlanGroupV2/ActionPlanGroupV2';
import { SubMetricData } from '../SubMetricData/SubMetricData';

import styles from './SubMetricDetail.module.scss';

export const getActionsForSubMetric = (
  actions: ActionPlanItem[],
  subMetricId?: number | string,
) => {
  if (!subMetricId) return actions;
  const numericIdToUse =
    typeof subMetricId === 'string' ? parseInt(subMetricId) : subMetricId;
  return actions.filter((item: ActionPlanItem) =>
    item.desired_outcomes?.some(
      outcome =>
        outcome.section === 'Gut Vaginal Health' &&
        outcome.gut_vaginal_health &&
        outcome.gut_vaginal_health.id === numericIdToUse,
    ),
  );
};

const InsightBreadcrumbs = ({ subMetric }: { subMetric?: InsightMetric }) => {
  if (!subMetric) return <></>;
  return (
    <div className={styles.breadcrumbs}>
      <Typography variant='body-s'>
        {
          subMetric.gut_vaginal_health?.key_insights_category
            ?.key_insights_sections?.display_title
        }
      </Typography>
      <Icon name='chevronForward' size='s' />
      <Typography variant='body-s'>
        {subMetric?.gut_vaginal_health?.key_insights_category?.category_title}
      </Typography>
    </div>
  );
};
const RelatedActions = ({ actions }: { actions: ActionPlanItem[] }) => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  if (!actions.length || !currentKitId) return null;
  return (
    <div className='flex flex-column gap-2'>
      <Typography variant='heading-l'>Action items for this insight</Typography>
      {actions.map(action => (
        <ActionPlanItemCard key={v4()} action={action} kitId={currentKitId} />
      ))}
      <LinkButton
        href={`/action-plan/${currentKitId}`}
        size={ButtonSize.M}
        color={ButtonColor.PURPLE}
        label='Go to action plan'
        fullWidth
      />
    </div>
  );
};
export const SubMetricDetail = () => {
  const { kitId, subMetricId } = useParams<{
    kitId: string;
    subMetricId: string;
  }>();
  const { state } = useLocation();
  const [{ data: subMetric }, { data: allActions }] = useSuspenseQueries({
    queries: [
      getSubMetricDetailQuery(kitId, subMetricId),
      getActionPlanItemsQuery(kitId),
      getTaxaOverTimeQuery(kitId, parseInt(subMetricId ?? '')),
    ],
  });
  const { isFeatureEnabled } = useFeature('ap_group_by_outcome');
  const startAPRef = useRef(null);
  const actionItems = getActionsForSubMetric(allActions, subMetricId);

  useEffect(() => {
    if (state?.scrollTo === 'actions') {
      if (startAPRef.current) {
        const offset = (startAPRef.current as HTMLElement).offsetTop - 10;
        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    }
  }, [state]);

  return (
    <PageContainer size={ContainerSizes.LG}>
      <InsightBreadcrumbs subMetric={subMetric} />
      <PageHeader title={subMetric?.name} />
      <div className={styles.sectionsContainer}>
        <main>
          <SubMetricData
            metric={subMetric}
            relatedActionsQty={actionItems?.length ?? 0}
            refAP={startAPRef}
          />
        </main>
        <section id='actions' ref={startAPRef}>
          {isFeatureEnabled ? (
            <RelatedActions actions={actionItems} />
          ) : (
            <ActionPlanGroup actionPlanItems={actionItems} withNavigation />
          )}
        </section>
      </div>
    </PageContainer>
  );
};
