import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets';
import { PractitionerKit } from 'types/PractitionerUser';

export const DISPLAY_NAMES: { [key: string]: string } = {
  GUT_HEALTH_TEST_PRACTITIONER: 'Gut Health Test',
  VAGINAL_HEALTH_TEST_PRACTITIONER: 'Vaginal Health Test',
  TINY_PRO_GUT_HEALTH_TEST: 'PRO Gut Health Test',
};

export async function getPractitionerKitsForPatient() {
  const response = await API.get(
    apiName,
    `${apiBasePath}/practitioners/patient-invoices/kits`,
    {},
  );
  return response?.result;
}

const usePractitionerKitsForPatient = () => {
  const practitionerKitsForPatientQuery = useQuery<PractitionerKit[]>({
    queryKey: [apiName, `practitionerKitsForPatient`],
    queryFn: () => getPractitionerKitsForPatient(),
  });

  return {
    ...practitionerKitsForPatientQuery,
    data: useMemo(
      () =>
        practitionerKitsForPatientQuery.data?.map(kit => {
          const display_title = `${
            DISPLAY_NAMES[kit.lookup_code]
              ? DISPLAY_NAMES[kit.lookup_code]
              : kit.name
          } - $${kit.price}`;
          return { ...kit, display_title };
        }),
      [practitionerKitsForPatientQuery.data],
    ),
  };
};

export default usePractitionerKitsForPatient;
