import { Suspense, lazy } from 'react';
import { ToastContainer } from 'react-toastify';

import { getColors } from 'contexts';

import { Spinfinity } from 'components';

import { MAINTENANCE_MODE, MAINTENANCE_TEXT } from 'assets/env_constants';

import { THRoutes } from 'routes';
import { useSystemInfo } from 'services/system-info/useSystemInfo';

const MaintenanceBanner = lazy(
  () => import('components/MaintenanceBanner/MaintenanceBanner'),
);
export const Layout = () => {
  useSystemInfo();
  return (
    <Suspense fallback={<Spinfinity fullScreen />}>
      {MAINTENANCE_MODE === 'true' && (
        <MaintenanceBanner text={MAINTENANCE_TEXT} />
      )}
      <div className='absolute'>
        <ToastContainer
          toastStyle={{
            backgroundColor: getColors().bg,
            color: getColors().fg,
          }}
        />
      </div>
      <THRoutes />
    </Suspense>
  );
};
