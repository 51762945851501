import { NavLink } from 'react-router-dom';
import c from 'classnames';

import { LinkButton, Icon, Typography, ButtonVariant } from 'components';
import { IconName } from 'components/Icon/getSvgByName';

import styles from './Sidebar.module.scss';

const SidebarItem = ({
  to,
  icon,
  label,
  onClick,
  state,
  external,
}: {
  to: string;
  icon: IconName;
  label: string;
  onClick?: () => void;
  state?: { tinyAccountId?: string; kitId?: string; from?: string };
  external?: boolean;
}) => {
  if (external) {
    return (
      <LinkButton
        label={label}
        href={to}
        external
        variant={ButtonVariant.WRAPPER}
        linkClassName={c(styles.sidebarItem, 'align-items-center')}
      >
        <Icon name={icon} />
        <Typography variant='body-s'>{label}</Typography>
      </LinkButton>
    );
  }
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        c(styles.sidebarItem, 'align-items-center', isActive && styles.active)
      }
      onClick={onClick}
      state={{ ...state }}
    >
      {({ isActive }) => (
        <>
          <Icon name={isActive ? ((icon + 'Filled') as IconName) : icon} />
          <Typography variant='body-s'>{label}</Typography>
        </>
      )}
    </NavLink>
  );
};

export default SidebarItem;
