import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import c from 'classnames';

import useFeature from 'services/features/useFeature';
import { getLatestKitForTinyAccountId } from 'store/kits';
import { getHomePageTinyAccountId } from 'store/home';
import { getShouldHideConsults } from 'store/account';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

import { Icon } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/getSvgByName';
import MobileMenu from 'components/MobileMenu/MobileMenu';

import styles from './MobileNav.module.scss';

const MobileNavItem = ({
  pathname,
  iconName,
  label,
  tinyAccountId,
}: {
  pathname: string;
  iconName: IconName;
  label: string;
  tinyAccountId?: string | null;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = useMemo(() => {
    return pathname.length
      ? location.pathname.startsWith(`/${pathname}`)
      : location.pathname === '/';
  }, [location, pathname]);

  return (
    <div
      className={styles.navMenuItem}
      onClick={() =>
        navigate(`/${pathname}`, {
          state: { tinyAccountId, from: location.pathname },
        })
      }
    >
      <Icon name={iconName} active={isActive} />
      <span className={c(styles.navMenuItemLabel, isActive && styles.active)}>
        {label}
      </span>
    </div>
  );
};

const MobileNav = () => {
  const { isFeatureEnabled: isHomeScreenEnabled } = useFeature('home_screen');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);
  const { currentKitId, currentTinyAccountId } = useCurrentKitAndTinyAccount();
  const homeScreenMatch = useMatch('/');
  const resultsMatch = useMatch<'kitId', string>('/results/:kitId');
  const actionPlanMatch = useMatch<'kitId', string>('/action-plan/:kitId');
  const shouldHideConsults = useSelector(getShouldHideConsults);
  const homePageTinyAccountId = useSelector(getHomePageTinyAccountId);
  const mostRecentKitIdFromHomePage = useSelector(
    getLatestKitForTinyAccountId(homePageTinyAccountId ?? null),
  )?.id;
  const [mostRecentKitId, setMostRecentKitId] = useState(currentKitId);
  const kitIdParam = homeScreenMatch
    ? mostRecentKitIdFromHomePage ?? ''
    : resultsMatch?.params.kitId ||
      actionPlanMatch?.params.kitId ||
      currentKitId ||
      mostRecentKitIdFromHomePage ||
      '';
  useEffect(() => {
    if (kitIdParam && kitIdParam !== mostRecentKitId) {
      setMostRecentKitId(kitIdParam);
    }
  }, [kitIdParam, mostRecentKitId]);

  return (
    <>
      <AnimatePresence>
        {mobileMenuOpen && <MobileMenu toggleMobileMenu={toggleMobileMenu} />}
      </AnimatePresence>
      <div className={styles.mobileNav}>
        {isHomeScreenEnabled && (
          <MobileNavItem
            pathname={``}
            iconName='home'
            label='Home'
            tinyAccountId={currentTinyAccountId}
          />
        )}
        <MobileNavItem
          pathname={`results/${mostRecentKitId ?? ''}`}
          iconName='lightOn'
          label='Results'
        />
        <MobileNavItem
          pathname={`action-plan/${mostRecentKitId ?? ''}`}
          iconName='today'
          label='Action'
        />
        {!isHomeScreenEnabled && (
          <MobileNavItem pathname='kits' iconName='add' label='Kits' />
        )}
        {shouldHideConsults ? (
          <MobileNavItem
            pathname='bookmarks'
            iconName='bookmark'
            label='Bookmarks'
          />
        ) : (
          <MobileNavItem
            pathname='consults'
            iconName='messageHearthSquare'
            label='Coaching'
          />
        )}
        <div className={styles.navMenuItem} onClick={toggleMobileMenu}>
          <Icon name='hamburger' active={mobileMenuOpen} />
          <span
            className={c(
              styles.navMenuItemLabel,
              mobileMenuOpen && styles.active,
            )}
          >
            Menu
          </span>
        </div>
      </div>
    </>
  );
};
export default MobileNav;
