import { Card, Icon, Typography } from 'components';

export const NewFamilyMemberCard = () => {
  return (
    <Card
      clickable={{
        text: 'Want to add another family profile?',
        href: `/family`,
        isExternal: false,
        variety: 'Card',
      }}
      className='hide-on-desktop'
    >
      <div className='flex align-items-center gap-2'>
        <Icon name='add' />
        <Typography variant='heading-s'>
          Want to add another family profile?
        </Typography>
      </div>
    </Card>
  );
};
