import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useModal from 'hooks/useModal';
import { getHasModalOpened, setModalOpened } from 'store/modal';
import { getMainAccountId } from 'store/account';

export const BlackFridayModal = ({
  children,
  enabled = false,
}: {
  children: React.ReactNode;
  enabled: boolean;
}) => {
  const dispatch = useDispatch();
  const { Modal, isOpen, setIsOpen } = useModal();
  const mainAccountId = useSelector(getMainAccountId);
  const blackFridayModal = {
    name: 'BlackFridayModal',
    props: { mainAccountId },
  };
  const hasOpenedBlackFridayModal = useSelector(
    getHasModalOpened(blackFridayModal),
  );

  useEffect(() => {
    if (enabled && !hasOpenedBlackFridayModal) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [enabled, hasOpenedBlackFridayModal]);
  useEffect(() => {
    if (isOpen && !hasOpenedBlackFridayModal) {
      dispatch(setModalOpened(blackFridayModal));
    }
  }, [isOpen, hasOpenedBlackFridayModal]);

  const ModalMemo = useMemo(() => Modal, [isOpen]);
  return <ModalMemo showHeader={false}>{children}</ModalMemo>;
};
