import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import c from 'classnames';

import { getKits } from 'store/shared';

import { useSystemInfo } from 'services/system-info/useSystemInfo';
import { useDownloadAllPDFs } from 'services/pdfReport/useDownloadAllPDFs';

import { ButtonColor, ButtonSize, DesignButton, Typography } from 'components';

import { KitStepProps } from 'views/Kits/ActivateKit';

import kitStyles from '../../Kits.module.scss';
import { useUpgradeKits } from 'services/kits/useUpgradeKits';
import { getMajorVersion } from 'helpers/getMajorVersion';
import useFeature from 'services/features/useFeature';
import kits from 'store/kits';
import { toast } from 'react-toastify';

type VersionUpdateSubStep = React.FC<{
  moveToNextScreen: () => Promise<void>;
  upgradeKits?: () => Promise<void>;
}>;
const StepZero: VersionUpdateSubStep = ({ moveToNextScreen }) => {
  const { latestSciVersion } = useSystemInfo();
  return (
    <div className='flex flex-column'>
      <Typography variant='heading-m' className='mb-2'>
        Would you like to update prior kits to Version {`${latestSciVersion}`}?
      </Typography>
      <Typography variant='body-s' className='mb-3'>
        Your prior kits were on previous versions of our system. We periodically
        make updates to reflect the latest scientific research. Your new kit
        will be on Version {`${latestSciVersion}`}, our latest system.
      </Typography>
      <Typography variant='body-s' className='mb-4'>
        If you choose to update your prior kits, ALL of the kits in your
        family’s account will update, including those for other family members.
        Flagged metrics, actions, and microbiome summary score may change with
        this update.
      </Typography>
      <DesignButton
        label='Next'
        type='button'
        size={ButtonSize.M}
        color={ButtonColor.LIGHT}
        fullWidth
        onClick={() => moveToNextScreen()}
      />
    </div>
  );
};
const StepOne: VersionUpdateSubStep = ({ moveToNextScreen }) => {
  const { downloadAllPDFs } = useDownloadAllPDFs();
  const hasMultiplePreviousKits =
    useSelector(getKits).filter(kits => kits.date_results_ready).length > 1;
  return (
    <div className='flex flex-column'>
      <Typography variant='heading-m' className='mb-3'>
        Kit updates could take up to 1 week.
      </Typography>
      <Typography variant='body-s' className='mb-4'>
        If you would like to save a version of your prior results, download{' '}
        {`${hasMultiplePreviousKits ? 'all of' : ''}`} your PDF
        {`${hasMultiplePreviousKits ? 's' : ''}`}{' '}
        <Typography variant='body-s' medium>
          before activating this kit.
        </Typography>
      </Typography>
      <DesignButton
        label='Next'
        type='button'
        size={ButtonSize.M}
        color={ButtonColor.LIGHT}
        fullWidth
        onClick={() => moveToNextScreen()}
      />
      <DesignButton
        label='Download all PDFs'
        type='button'
        size={ButtonSize.M}
        color={ButtonColor.GREEN}
        className='mt-4'
        fullWidth
        onClick={async () => downloadAllPDFs()}
      />
    </div>
  );
};
const StepTwo: VersionUpdateSubStep = ({ moveToNextScreen }) => {
  const { latestSciVersion } = useSystemInfo();
  const upgradeKitsMutation = useUpgradeKits();
  const upgradeKits = useCallback(async () => {
    await upgradeKitsMutation.mutateAsync();

    moveToNextScreen();
  }, [upgradeKitsMutation, moveToNextScreen]);
  return (
    <div className='flex flex-column'>
      <Typography variant='heading-m' className='mb-3'>
        I understand that ALL of my family’s kits will update to Version{' '}
        {`${latestSciVersion}`}.
      </Typography>
      <Typography variant='heading-m' medium className='mb-4'>
        This cannot be undone.
      </Typography>
      <DesignButton
        label='No, do not update'
        type='button'
        size={ButtonSize.M}
        color={ButtonColor.DARK}
        fullWidth
        onClick={() => moveToNextScreen()}
      />
      <DesignButton
        label='Yes, please update'
        type='button'
        size={ButtonSize.M}
        color={ButtonColor.GREEN}
        className='mt-4'
        fullWidth
        onClick={() => upgradeKits()}
      />
    </div>
  );
};

const steps = [
  { id: 0, Component: StepZero },
  { id: 1, Component: StepOne },
  { id: 2, Component: StepTwo },
];

const VersionUpdateStep = ({
  setActivationState,
  activationState,
  setCurrentActivationStep,
  step,
}: KitStepProps) => {
  const { kit } = activationState;
  const { isFeatureEnabled: versionUpgradeIsEnabled } =
    useFeature('version_upgrade');
  const { latestSciVersion } = useSystemInfo();
  const kits = useSelector(getKits);

  const [localStep, setLocalStep] = useState(0);

  const previousKitMajorVersion = useMemo(() => {
    let majorVersion = 0;
    if (kits.length) {
      majorVersion = Math.max(
        ...kits.map(kit => getMajorVersion(kit?.sci_version ?? '') ?? 0),
      );
    }
    return majorVersion;
  }, [kits]);
  const isResample = useMemo(() => {
    if (
      kits.find(
        oKit =>
          kit.tinyaccount_id === oKit.tinyaccount_id && oKit.date_results_ready,
      )
    ) {
      return true;
    }

    return false;
  }, [kit.tinyaccount_id, kits]);

  useEffect(() => {
    if (
      !versionUpgradeIsEnabled ||
      !isResample ||
      previousKitMajorVersion >= getMajorVersion(latestSciVersion ?? '')
    ) {
      setCurrentActivationStep(step + 1);
    }
  }, [
    isResample,
    kit,
    latestSciVersion,
    previousKitMajorVersion,
    setCurrentActivationStep,
    step,
    versionUpgradeIsEnabled,
  ]);
  const moveToNextScreen = async () => {
    if (localStep < 2) {
      setLocalStep(localStep + 1);
      return;
    }
    setCurrentActivationStep(step + 1);
  };
  const StepComponent = steps[localStep].Component;

  return (
    <div className={c(kitStyles.stepContainer)}>
      <StepComponent moveToNextScreen={moveToNextScreen} />
    </div>
  );
};

export default VersionUpdateStep;
