import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { EMAIL_VALIDATION_PATTERN } from 'assets';
import useUpdateMainAccount from 'services/account/useUpdateMainAccount';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Button, THField, Typography } from 'components';

import styles from './AccountModal.module.scss';

type AccountEmailModalProps = {
  toggle: () => void;
  showModal: boolean;
};
type AccountEmailModalFormValues = {
  email: string;
};

const AccountEmailModal = ({ toggle, showModal }: AccountEmailModalProps) => {
  const mainAccountMutation = useUpdateMainAccount();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<AccountEmailModalFormValues>({
    mode: 'onTouched',
  });

  const emailFormField = register('email', {
    required: { value: true, message: 'Please enter the new email' },
    pattern: EMAIL_VALIDATION_PATTERN,
  });

  const submitMainAccountUpdateForm = async (
    formData: AccountEmailModalFormValues,
  ) => {
    try {
      await mainAccountMutation.mutateAsync({
        body: {
          email: formData.email?.trim(),
        },
      });
      toast.success('Email updated');
    } catch (error) {
      toast.error('Your request could not be completed. Please try again');
    }
    toggle();
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggle}
      fullscreen='md'
      className={styles.modalContainer}
    >
      <ModalHeader toggle={toggle} className={styles.modalHeader}>
        <Typography variant='heading-s'>Edit email</Typography>
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        <form onSubmit={handleSubmit(submitMainAccountUpdateForm)}>
          <THField
            type='text'
            label='Email'
            errors={errors}
            {...emailFormField}
          />
          <Button
            type='submit'
            color='green'
            disabled={!isValid}
            loading={isSubmitting}
          >
            Save
          </Button>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default AccountEmailModal;
