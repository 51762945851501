import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import { kitResultsViewable, sortKitsByResultsViewableOrLatest } from 'helpers';
import useActionPlanData from 'services/actionPlan/actionPlanData';
import useFeature from 'services/features/useFeature';
import { getKitById } from 'store/kits';
import { getTinyAccountById } from 'store/account';
import { getKits } from 'store/shared';

import {
  Spinfinity,
  OrderKitButton,
  Header,
  PageContainer,
  ContainerSizes,
  Typography,
} from 'components';
import NoActionsAvailable from './NoActionsAvailable/NoActionsAvailable';
import { ActionRelatedInsights } from './ActionRelatedInsights/ActionRelatedInsights';
import { ActionPlanAccordionList as APListGroupedByOutcome } from './ActionPlanAccordionList/ActionPlanAccordionListV2';
import { ActionPlanAccordionList } from './ActionPlanAccordionList';
import { ActionPlanDetail } from './ActionPlanDetail';
import EmptyActionPlan from 'views/ActionPlan/EmptyActionPlan';

import styles from './ActionPlan.module.scss';
import VersionPill from 'components/VersionPill/VersionPill';

export const ActionPlan = () => {
  const { isFeatureEnabled: showActionPlanGroupedByOutcome } = useFeature(
    'ap_group_by_outcome',
  );
  const resultMatch = useMatch<'kitId', string>('/results/:kitId');
  const actionPlanMatch = useMatch<'kitId', string>('/action-plan/:kitId');
  const kits = useSelector(getKits);
  const defaultKitId = sortKitsByResultsViewableOrLatest(kits)[0]?.id;
  const currentKitId =
    resultMatch?.params?.kitId || actionPlanMatch?.params?.kitId;
  const currentKit = useSelector(getKitById(currentKitId));
  const currentKitHasResults = currentKit && kitResultsViewable(currentKit);
  const currentTinyAccountId = currentKit?.tinyaccount_id;
  const currentTinyAccount = useSelector(
    getTinyAccountById(currentTinyAccountId),
  );
  const { data } = useActionPlanData({ kitId: currentKitId });

  let NextAction = null;
  if (!resultMatch && !currentKitId && defaultKitId) {
    NextAction = <Navigate to={`/action-plan/${defaultKitId}`} replace />;
  } else if (!data?.length) {
    NextAction = <NoActionsAvailable />;
  } else {
    NextAction = <EmptyActionPlan />;
  }

  return (
    <section id='action_plan'>
      <Header title={`${currentTinyAccount?.first_name}’s Action Plan`} />
      <PageContainer size={ContainerSizes.MD}>
        <div className={styles.actionPlanWrap}>
          <Suspense fallback={<Spinfinity />}>
            {currentKitHasResults && !!currentKit?.date_survey_completed ? (
              <>
                <Suspense fallback={<Spinfinity />}>
                  {showActionPlanGroupedByOutcome ? (
                    <APListGroupedByOutcome
                      kit={currentKit}
                      isActionPlanPage={!!actionPlanMatch}
                    />
                  ) : (
                    <>
                      {actionPlanMatch ? (
                        <>
                          {currentKit && !!data?.length && (
                            <p className='mt-2'>
                              Your personalized action plan includes steps you
                              can take to support a healthy microbiome.
                            </p>
                          )}
                        </>
                      ) : (
                        <div>
                          <Typography variant='heading-l'>
                            Action Plan
                          </Typography>
                        </div>
                      )}

                      <ActionPlanAccordionList
                        kit={currentKit}
                        isActionPlanPage={!!actionPlanMatch}
                      />
                    </>
                  )}
                  {currentKit?.sci_initial_version && (
                    <div className='flex gap-2 mt-3'>
                      <Typography variant='label' className='my-auto'>
                        System version
                      </Typography>
                      <VersionPill version={currentKit.sci_version ?? null} />
                    </div>
                  )}
                </Suspense>
                {currentKit && !!resultMatch && (
                  <OrderKitButton classes={'pt-4'}>
                    Order your next kit
                  </OrderKitButton>
                )}
                <div className={styles.disclaimers}>
                  <Typography variant='body-s' serif>
                    Always check with your provider before making any changes to
                    your diet or supplement routine. This information is not
                    intended to replace professional medical advice.
                  </Typography>
                  <Typography variant='body-s' serif>
                    This page includes affiliate links. When you buy through
                    links on our site, we may earn an affiliate commission. Tiny
                    Health only recommends items that we’ve researched and stand
                    behind.
                  </Typography>
                </div>
              </>
            ) : (
              <Suspense fallback={<Spinfinity />}>{NextAction}</Suspense>
            )}
          </Suspense>
        </div>
      </PageContainer>
    </section>
  );
};

export const ActionPlanRoutes = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  return (
    <Routes>
      <Route
        path='/action/:actionId/insights'
        element={<ActionRelatedInsights />}
      />
      <Route path='/action/:actionId' element={<ActionPlanDetail />} />{' '}
      <Route
        path={`/action`}
        key='insights'
        element={<Navigate to={`/action-plan/${currentKitId}`} replace />}
      />
      <Route path='' element={<ActionPlan />} />
    </Routes>
  );
};
