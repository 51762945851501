import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import c from 'classnames';

import { getEmail } from 'store/user';
import useSignIn from 'services/auth/useSignIn';

import { EMAIL_VALIDATION_PATTERN, PLACEHOLDER } from 'assets';
import { ButtonColor, ButtonSize, DesignButton, THField } from 'components';

import styles from '../auth.module.scss';

type SignInFormValues = {
  email: string;
  password: string;
};

const SignInForm = ({ darkBackground }: { darkBackground?: boolean }) => {
  const userEmail = useSelector(getEmail);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInFormValues>({
    mode: 'onTouched',
    defaultValues: {
      email: userEmail,
    },
  });
  const signIn = useSignIn();
  const onSubmitSignIn = async (formData: SignInFormValues) => {
    try {
      await signIn(formData.email, formData.password);
    } catch (err) {
      const error = err as Error;
      if (error?.name) {
        if (
          error.name === 'NotAuthorizedException' ||
          error.name === 'UserNotFoundException'
        ) {
          toast.error('Email or password is incorrect');
        } else {
          toast.error('Something went wrong, please wait and try again');
        }
      } else {
        toast.error('Something went wrong, please wait and try again');
      }
    }
  };

  const emailFormField = register('email', {
    required: { value: true, message: 'Please enter your email' },
    pattern: EMAIL_VALIDATION_PATTERN,
  });

  const passwordFormField = register('password', {
    required: { value: true, message: 'Please enter a password' },
    minLength: { value: 8, message: 'Please use at least 8 characters' },
  });

  return (
    <>
      <form
        id='sign-in-form'
        name='sign-in-form'
        className={c(styles.authForm, darkBackground && styles.darkBackground)}
        onSubmit={handleSubmit(onSubmitSignIn)}
      >
        <THField
          type='email'
          label='Email address'
          icon='mail'
          classes={c(darkBackground && styles.darkBackground)}
          errors={errors}
          placeholder={PLACEHOLDER.email}
          defaultValue={userEmail}
          {...emailFormField}
        />
        <THField
          type='password'
          label='Password'
          icon='lock'
          classes={c(darkBackground && styles.darkBackground)}
          errors={errors}
          placeholder={PLACEHOLDER.password}
          {...passwordFormField}
        />
        <DesignButton
          label='Sign in'
          type='submit'
          loading={isSubmitting}
          className={styles.button}
          color={ButtonColor.PURPLE}
          fullWidth
          size={ButtonSize.M}
        />
      </form>
    </>
  );
};
export default SignInForm;
