import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import c from 'classnames';

import useRequestPDFReport from 'services/pdfReport/useRequestPDFReport';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { kitStatusColorParser, kitStatusParser } from 'helpers/kitStatuses';
import {
  capitalize,
  stoolToGut,
  parseDate,
  sortKitsByResultsViewableOrLatest,
} from 'helpers';
import { getKitById, getKitsForTinyAccountId } from 'store/kits';
import { getTinyAccounts } from 'store/account';
import Kit, { KitType } from 'types/Kit';

import { Badge, Typography } from 'components';
import { Dropdown } from 'components/Elements';
import { Icon } from '../Icon/Icon';

import styles from './KitSelect.module.scss';

export const KitSelect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { currentKit, currentKitType, currentKitId, currentTinyAccountId } =
    useCurrentKitAndTinyAccount();
  const kits = useSelector(getKitsForTinyAccountId(currentTinyAccountId)); // only show kits for this tiny account

  const sortedKits = sortKitsByResultsViewableOrLatest(kits);
  const currentKitTypeIsStool = currentKitType === 'stool';
  const tinyAccounts = useSelector(getTinyAccounts);

  const setCurrentKit = (kitId: string) => {
    if (!kitId) return;
    const newPath = pathname.replace(`/${currentKitId}`, `/${kitId}`);
    navigate(newPath, { replace: true });
  };
  if (!kits || !currentKit) return null;

  if (!tinyAccounts?.length || !kits.length) return null;
  const kitSelectOptions = sortedKits.map((kit: Kit) => ({
    value: kit.id,
  }));
  const controlLabel = `${capitalize(
    stoolToGut(currentKitType ?? KitType.STOOL),
  )} test`;
  return (
    <>
      <Dropdown
        options={kitSelectOptions}
        value={currentKit.id}
        onChange={(value: string) => setCurrentKit(value)}
        placeholder={null}
        CustomOption={({ innerProps, ...props }) => (
          <KitSelectOption {...innerProps} {...props} />
        )}
        controlIconString={currentKitTypeIsStool ? 'swab' : 'lotus'}
        controlLabel={controlLabel}
        controlSubLabel={format(parseDate(currentKit.sample_date), 'MM/dd/yy')}
        hideControlLabelMobile
        // menuIsOpen
      />
    </>
  );
};

const KitSelectOption = ({ ...props }) => {
  // option values are kitIds
  const kit = useSelector(getKitById(props.value));
  if (!kit) return null;
  return (
    <div className={styles.kitSelectOption}>
      <div className={styles.iconLabelContainer}>
        <div className={styles.iconContainer}>
          <Icon
            size='m'
            name={kit.sampling_type === 'stool' ? 'swab' : 'lotus'}
          />
        </div>

        <div>
          <div className={styles.kitLabel}>{`${capitalize(
            stoolToGut(kit.sampling_type ?? KitType.STOOL),
          )} test`}</div>
          {kit.sample_date && (
            <div className={styles.kitSubLabel}>
              {format(parseDate(kit.sample_date), 'MM/dd/yy')}
            </div>
          )}
        </div>
      </div>

      <div className={styles.sampleLabel}>
        <Typography variant='label'>{kit.sample_label}</Typography>
      </div>

      <Badge
        title={kitStatusParser(kit.status)}
        state={kitStatusColorParser(kit.status)}
      />
    </div>
  );
};

export const SelectedKit = () => {
  const { currentKit, currentKitId, currentKitHasResults } =
    useCurrentKitAndTinyAccount();
  const { signedUrl } = useRequestPDFReport(currentKitId);
  const { pathname } = useLocation();

  const viewPDFReport = useCallback(async () => {
    if (signedUrl) window.open(signedUrl, '_blank');
  }, [signedUrl]);

  if (!currentKit) return null;
  return (
    <div className={styles.selectedKitContainer}>
      <div className={c(styles.hideOnMobile, styles.kitInfo)}>
        <div className={styles.itemWrapper}>
          <Icon name='atSignFilled' size='s' />
          <Typography variant='label'>{currentKit.sample_label}</Typography>
        </div>
        <div className={styles.itemWrapper}>
          <Icon name='priceTagFilled' size='s' />
          <Typography variant='label'>{`Kit ${currentKit.id}`}</Typography>
        </div>
      </div>
      <div className={styles.kitInfo}>
        <div className={c(styles.itemWrapper, styles.hideOnDesktop)}>
          <Icon name='priceTagFilled' size='s' />
          <Typography variant='label'>{`Kit ${currentKit.id}`}</Typography>
        </div>
        {currentKit.date_survey_completed && currentKitHasResults && (
          <>
            <div
              className={c(
                styles.itemWrapper,
                styles.hideOnMobile,
                'clickable',
              )}
              onClick={viewPDFReport}
            >
              <Icon name='download' size='s' />
              <Typography variant='label'>Download PDF</Typography>
            </div>
            <div
              className={c(
                styles.itemWrapper,
                styles.hideOnDesktop,
                'clickable',
              )}
              onClick={viewPDFReport}
            >
              <Icon name='download' size='s' />
              <Typography variant='label'>PDF</Typography>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
