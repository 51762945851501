import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import { getUsername } from 'store/user';

type KitOrderBody = {
  product_id: number;
  variant_id: number;
  customer_email: string;
  customer_first_name: string;
  customer_last_name: string;
  customer_state: string;
};

export const getKitOrderForPatient = async (body: KitOrderBody) => {
  const response = await API.post(
    apiName,
    `${apiBasePath}/practitioners/patient-invoices`,
    {
      ...body,
      quantity: 1,
    },
  );
  return response;
};

const useGetKitOrderForPatient = (
  showValidationErrorModal: Dispatch<SetStateAction<boolean>>,
) => {
  const queryClient = useQueryClient();
  const username = useSelector(getUsername);

  const mutation = useMutation({
    mutationFn: (body: KitOrderBody) => {
      return getKitOrderForPatient(body);
    },
    onSuccess: async (data, variables, context) => {
      toast.success('Kit order link sent to your patient');
      //refresh table data
      const queryKey = [apiName, 'useKitRequests', username];
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (err, variables, context) => {
      const axios_error = err as AxiosError;
      if (axios_error.response?.status === 400) {
        showValidationErrorModal(true);
      } else {
        toast.error('Something went wrong, please try again');
      }
    },
  });
  return mutation;
};

export default useGetKitOrderForPatient;
