import { NavLink } from 'react-router-dom';
import c from 'classnames';

import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';

import { Icon, Typography } from 'components';

import styles from './DesignButton.module.scss';
import { IconName } from 'components/Icon/getSvgByName';

export enum ButtonSize {
  M = 'medium',
  S = 'small',
}
export enum ButtonVariant {
  TEXT = 'text',
  SOLID = 'solid',
  WRAPPER = 'wrapper',
}
export enum ButtonColor {
  GREEN = 'green',
  PURPLE = 'purple',
  DARK_PURPLE = 'darkPurple',
  RED = 'red',
  DARK = 'dark',
  LIGHT = 'light',
  WHITE = 'white',
}
export type ButtonColorType =
  | 'green'
  | 'purple'
  | 'darkPurple'
  | 'red'
  | 'dark'
  | 'light'
  | 'white';

interface DesignButtonProps {
  label: string;
  size?: ButtonSize;
  variant?: ButtonVariant;
  type?: 'button' | 'submit' | 'reset';
  color?: ButtonColorType;
  className?: string;
  iconNameLeft?: IconName;
  iconNameRight?: IconName;
  fullWidth?: boolean;
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: () => Promise<void>;
  hrefTracking?: string;
  isExternalTracking?: boolean;
}
export const DesignButton = ({
  label,
  size = ButtonSize.S,
  variant = ButtonVariant.SOLID,
  color = ButtonColor.GREEN,
  loading = false,
  disabled = false,
  fullWidth = false,
  className = '',
  iconNameLeft,
  iconNameRight,
  onClick,
  hrefTracking,
  isExternalTracking,
  children,
  ...props
}: DesignButtonProps) => {
  const { trackEvent } = useAnalytics();

  const handleClick = () => {
    //click handler from props
    if (onClick) {
      onClick();
    }
    //tracking of the clicked event
    if (!children || typeof children === 'string') {
      trackEvent('Clicked Button', {
        text: label,
        href: hrefTracking ?? '',
        isExternal: isExternalTracking ?? false,
        variety: hrefTracking ? 'LinkButton' : 'Button',
      });
    }
  };

  const buttonClasses = c(
    styles.button,
    styles.buttonFlex,
    styles[size],
    styles[variant],
    styles[color],
    variant === ButtonVariant.WRAPPER && styles.onlyWrapper,
    loading && styles.loading,
    fullWidth && styles.full,
    disabled && styles.disabled,
    className,
  );
  return (
    <button
      {...props}
      className={buttonClasses}
      disabled={!!loading || !!disabled}
      type={props.type ?? 'button'}
      onClick={handleClick}
    >
      {children ? (
        children
      ) : (
        <ButtonContent
          loading={loading}
          iconNameLeft={iconNameLeft}
          iconNameRight={iconNameRight}
        >
          <Typography variant={size === ButtonSize.S ? 'label' : 'body-s'}>
            {label}
          </Typography>
        </ButtonContent>
      )}
    </button>
  );
};

const ButtonIconLeft = ({ iconName }: { iconName: IconName }) => (
  <Icon size='s' name={iconName} className={styles.iconLeft} />
);
const ButtonIconRight = ({ iconName }: { iconName: IconName }) => (
  <Icon size='s' name={iconName} className={styles.iconRight} />
);

type ButtonContentProps = {
  loading?: boolean;
  iconNameLeft?: IconName;
  iconNameRight?: IconName;
  children?: React.ReactNode | string;
};
const ButtonContent = ({
  children,
  loading = false,
  iconNameRight,
  iconNameLeft,
}: ButtonContentProps) =>
  loading ? (
    <span>Loading...</span>
  ) : (
    <div className='flex gap-1'>
      {iconNameLeft ? <ButtonIconLeft iconName={iconNameLeft} /> : null}
      {children}
      {iconNameRight ? <ButtonIconRight iconName={iconNameRight} /> : null}
    </div>
  );

interface LinkButtonProps extends DesignButtonProps {
  href: string | { pathname: string; search?: string };
  state?: { from?: string; tinyAccountId?: string | null };
  external?: boolean;
  linkClassName?: string;
}
export const LinkButton = ({
  href,
  state,
  external,
  fullWidth = false,
  linkClassName,
  ...props
}: LinkButtonProps) => {
  return external && typeof href === 'string' ? (
    <a
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      //flag to know that it is already tracked and avoid double tracking in the general link tracking from AnalyticsProvider
      data-tracking-source='LinkButton'
      className={linkClassName}
    >
      <DesignButton
        {...props}
        fullWidth={fullWidth}
        hrefTracking={href}
        isExternalTracking={external}
      />
    </a>
  ) : (
    <NavLink
      to={href}
      state={state}
      className={c(fullWidth && 'w-100', linkClassName)}
    >
      <DesignButton {...props} fullWidth={fullWidth} />
    </NavLink>
  );
};
