import c from 'classnames';

import { LINKS } from 'assets';
import useCheckoutKit from 'hooks/useCheckoutKit';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import useFeature from 'services/features/useFeature';

import { ButtonColor, ButtonSize, LinkButton, Typography } from 'components';
import { BlackFridayModal } from './BlackFridayModal/BlackFridayModal';
import blackFridayRibbonImage from 'assets/images/home/black-friday-tape.png';

import styles from './BlackFridaySale.module.scss';

const getCheckoutUrlWithBlackFridayDiscount = (checkoutUrl: string) => {
  //if they are tiny_plus_proactive or tiny_plus_program they get a BF discount code
  if (
    checkoutUrl === LINKS.checkoutWithKitTinyPlusProgramOrProactive ||
    checkoutUrl === LINKS.checkoutWithKitTinyPlusProgramOrProactiveVaginal
  ) {
    return `${checkoutUrl}&discount=BFCM20`;
  }
  //tiny_plus legacy doesn't get the code
  return checkoutUrl;
};

export const BlackFridaySaleModal = ({
  tinyAccountId,
}: {
  tinyAccountId: string | null;
}) => {
  const { isFeatureEnabled: isBlackFridayEnabled } =
    useFeature('black_friday_sale');
  const { latestKit, hasMembership } = useTinyAccountForHome(tinyAccountId);
  const { checkoutUrl } = useCheckoutKit(latestKit?.sampling_type);
  const ctaLink = hasMembership
    ? getCheckoutUrlWithBlackFridayDiscount(checkoutUrl)
    : LINKS.store;

  if (!isBlackFridayEnabled) return <></>;
  return (
    <BlackFridayModal enabled={true}>
      <div className={c(styles.modalContainer)}>
        <div className={styles.textContent}>
          <Typography variant='label' weight='bold'>
            BLACK FRIDAY SALE
          </Typography>
          {hasMembership ? (
            <Typography variant='heading-l'>
              $149 <span className={styles.originalPrice}>$169</span> for test
              kits
            </Typography>
          ) : (
            <Typography variant='heading-l'>Up to $50 off site-wide</Typography>
          )}
          <Typography variant='label'>
            {hasMembership
              ? 'Exclusive savings for Tiny+ Members'
              : 'site-wide gut & vaginal health testing'}
          </Typography>
        </div>
        <div className='mr-4'>
          <LinkButton
            href={ctaLink}
            external
            label='Shop Sale'
            color={ButtonColor.PURPLE}
            size={ButtonSize.M}
          />
        </div>
        <div className={styles.blackFridayBox}></div>
      </div>
    </BlackFridayModal>
  );
};

export function BlackFridaySale({
  tinyAccountId,
}: {
  tinyAccountId: string | null;
}) {
  const { isFeatureEnabled: isBlackFridayEnabled } =
    useFeature('black_friday_sale');
  const { latestKit, hasMembership } = useTinyAccountForHome(tinyAccountId);
  const { checkoutUrl } = useCheckoutKit(latestKit?.sampling_type);
  const ctaLink = hasMembership
    ? getCheckoutUrlWithBlackFridayDiscount(checkoutUrl)
    : LINKS.store;

  if (!isBlackFridayEnabled) return <></>;
  return (
    <>
      <div className={c(styles.container)}>
        <div className={c('hide-on-mobile', styles.blackFridayTape)}>
          <img src={blackFridayRibbonImage} alt='Black friday deal' />
        </div>
        <div className={styles.textContent}>
          <Typography variant='label' weight='bold'>
            BLACK FRIDAY SALE
          </Typography>
          {hasMembership ? (
            <Typography variant='heading-l'>
              $149 <span className={styles.originalPrice}>$169</span> for test
              kits
            </Typography>
          ) : (
            <Typography variant='heading-l'>Up to $50 off site-wide</Typography>
          )}
        </div>
        <div className={styles.ctaWrapper}>
          <LinkButton
            href={ctaLink}
            external
            label='Shop Sale'
            color={ButtonColor.PURPLE}
            size={ButtonSize.M}
          />
        </div>
        <div className='hide-on-desktop'>
          <div className={styles.blackFridayBox}></div>
        </div>
      </div>
    </>
  );
}
