import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
const getSystemInfo = async () => {
  const response: { sci_version_latest: string } = await API.get(
    'publicapi',
    `/public/system-info`,
    {},
  );
  return response;
};

export const useSystemInfo = () => {
  const { data: systemInfo } = useQuery({
    queryKey: ['systemInfo'],
    queryFn: () => getSystemInfo(),
  });
  return {
    latestSciVersion: systemInfo?.sci_version_latest,
  };
};
