import { Suspense, useMemo } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import c from 'classnames';

import { getFirstParagraph } from 'helpers';
import useActionPlanItem from 'services/actionPlan/actionPlanItem';
import useAllBookmarks from 'services/actionPlan/useAllBookmarks';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import {
  ActionPlanDesiredOutcome,
  ActionPlanItem,
  BookmarkedItem,
} from 'types/ActionPlan';
import { FeedbackPageType } from 'types/Feedback';
import Guide from 'types/Guide';

import {
  FAQ,
  EvidenceRating,
  Modal,
  PageContainer,
  Card,
  Icon,
  ContainerSizes,
  CardSlider,
  TextLink,
  FeedbackWidget,
  Header,
  Typography,
  Markdown,
  Spinfinity,
} from 'components';
import HeaderIllustration from 'components/Header/HeaderIllustration';
import { GuideCard } from 'views/Guides/GuideCard/GuideCard';
import { Recommendations } from './Recommendations/Recommendations';
import {
  DesiredOutcome,
  DesiredOutcomesList,
} from './DesiredOutcomes/DesiredOutcomes';
import { ActionDisplayTag } from './ActionDisplayTag/ActionDisplayTag';
import { WytuCardAP } from './WytuCardAP/WytuCardAP';
import { IconName } from 'components/Icon/getSvgByName';

import styles from './ActionPlanDetail.module.scss';

const DetailHeader = ({ action }: { action: ActionPlanItem }) => {
  const tags: string[] = useMemo(() => {
    return action?.display_tags ? action.display_tags.split(',') : [];
  }, [action]);

  return (
    <div className={styles.headerContainer}>
      <>
        {!!tags?.length && (
          <div className={styles.tagsContainer}>
            {tags.map(tag => (
              <ActionDisplayTag name={tag} key={tag} />
            ))}
          </div>
        )}
        <Typography variant='heading-xl'>{action.display_title}</Typography>
        {!!action?.subtitle && (
          <div className={styles.subtitle}>
            <Markdown>{action.subtitle}</Markdown>
          </div>
        )}
      </>
    </div>
  );
};
const Instruction = ({
  instruction,
  icon,
}: {
  instruction: string;
  icon?: IconName;
}) => {
  return (
    <div className={styles.instructions}>
      {icon && (
        <div className='flex'>
          <Icon name={icon} size='m' className='my-auto' />
        </div>
      )}
      <div className='my-auto'>
        <Typography variant='body-s' responsive>
          {instruction}
        </Typography>
      </div>
    </div>
  );
};
const Instructions = ({ action }: { action: ActionPlanItem }) => {
  if (!action.instruction_1 && !action.instruction_2 && !action.instruction_3)
    return null;
  return (
    <Card>
      <div className={styles.instructionsContainer}>
        {!!action.instruction_1 && (
          <Instruction
            instruction={action.instruction_1}
            icon={action.instruction_1_icon as IconName}
          />
        )}
        {!!action.instruction_2 && (
          <Instruction
            instruction={action.instruction_2}
            icon={action.instruction_2_icon as IconName}
          />
        )}
        {!!action.instruction_3 && (
          <Instruction
            instruction={action.instruction_3}
            icon={action.instruction_3_icon as IconName}
          />
        )}
      </div>
    </Card>
  );
};

export const ActionPlanDetail = () => {
  const { actionId } = useParams<{ actionId?: string }>();
  const { currentKitId, currentTinyAccount } = useCurrentKitAndTinyAccount();
  const MAX_OUTCOMES = 3;

  const { isSuccess, data: action } = useActionPlanItem({
    kitId: currentKitId,
    actionId,
  });
  const { data: bookmarks } = useAllBookmarks();

  const actionActiveBookmarks = useMemo(() => {
    return bookmarks?.filter(
      (bookmark: BookmarkedItem) =>
        bookmark.is_bookmarked && bookmark.kit_id === currentKitId,
    );
  }, [bookmarks, currentKitId]);

  const aboutIntro = useMemo(() => {
    return getFirstParagraph(action?.about);
  }, [action?.about]);
  const outcomesToDisplay =
    action?.desired_outcomes?.slice(0, MAX_OUTCOMES) ?? [];
  const hasOutcomesWithInsightsIds = outcomesToDisplay.some(
    (outcome: ActionPlanDesiredOutcome) =>
      !!outcome.criteria && /^\d+$/.test(outcome.criteria),
  );

  const guidesToShow = useMemo(() => {
    return !action?.guides?.length
      ? []
      : action.guides.map((guide: Guide) => (
          <GuideCard key={guide.id} guide={guide} />
        ));
  }, [action?.guides]);

  if (!currentTinyAccount || !currentKitId || !actionId || !action) {
    return null;
  }
  if (currentKitId && actionId && isSuccess && !action)
    return <Navigate to={`/action-plan/${currentKitId}`} replace />;
  return action?.v2 ? (
    <>
      <Header title={`${currentTinyAccount.first_name}’s action plan`} />
      <Suspense fallback={<Spinfinity />}>
        {!!action?.cover_url?.url && (
          <HeaderIllustration
            url={action.cover_url.url}
            bgColor={action.cover_url.color}
          />
        )}
        <PageContainer
          size={ContainerSizes.MD}
          className={styles.pageContainer}
        >
          <DetailHeader action={action} />
          <div className={styles.cardsWrapper}>
            <Instructions action={action} />

            {hasOutcomesWithInsightsIds && (
              <Card>
                <h4 className={styles.cardHeading}>
                  Why do we recommend it
                  {currentTinyAccount?.first_name
                    ? ` for ${currentTinyAccount.first_name}`
                    : ''}
                  ?
                </h4>
                {/* show up to MAX_OUTCOMES and complete list in the modal */}
                {outcomesToDisplay?.map((outcome: ActionPlanDesiredOutcome) => (
                  <DesiredOutcome
                    outcome={outcome}
                    key={outcome.id + '_' + outcome.title}
                  />
                ))}
                <div className='mt-3'>
                  <TextLink
                    href={`/action-plan/${currentKitId}/action/${actionId}/insights`}
                    label='See all related insights'
                  />
                </div>
              </Card>
            )}
          </div>

          {!!action?.action_plan_personalization?.length && (
            <WytuCardAP action={action} />
          )}

          <Typography
            variant='heading-l'
            className={c(styles.detailHeading, 'mt-4')}
          >
            About
          </Typography>
          {action?.evidence_rating && (
            <EvidenceRating evidenceRating={action.evidence_rating} />
          )}
          <div
            className={c(styles.detailAbout, action?.evidence_rating && 'mt-2')}
          >
            <Markdown>{aboutIntro ?? ''}</Markdown>
            <Modal
              title='About this metric'
              label={<span className={styles.detailMore}>Learn more</span>}
            >
              {action.name && (
                <Typography
                  variant='heading-l'
                  className={c(styles.detailHeading, 'mt-2')}
                >
                  {action.name}
                </Typography>
              )}
              <Markdown>{action?.about ?? ''}</Markdown>
            </Modal>
          </div>

          <Recommendations
            recommendations={action.recommendations}
            activeBookmarks={actionActiveBookmarks}
            actionId={action?.id}
          />

          <div className='mb-4'>
            <FeedbackWidget
              kitId={currentKitId}
              pageType={FeedbackPageType.ACTION}
              pageId={parseInt(actionId)}
            />
          </div>

          <div className='mb-4'>
            {!!action?.faq && (
              <div className='pb-4'>
                <Typography
                  variant='heading-l'
                  className={styles.detailHeading}
                >
                  Frequently asked questions
                </Typography>
                <FAQ faq={action.faq} />
              </div>
            )}
            {!!action?.guides?.length && (
              <div>
                <div className={styles.guidesRow}>
                  <Typography
                    variant='heading-l'
                    className={styles.detailHeading}
                  >
                    Related Articles
                  </Typography>
                  <TextLink label='View all' href={`/guides`} />
                </div>
                <div className={styles.guidesGridListContainer}>
                  {guidesToShow}
                </div>
                <div className={styles.guidesSliderContainer}>
                  <CardSlider cards={guidesToShow} />
                </div>
              </div>
            )}
          </div>
          <div className={styles.disclaimers}>
            <Typography variant='body-s' serif>
              Always check with your provider before making any changes to your
              diet or supplement routine. This information is not intended to
              replace professional medical advice.
            </Typography>
            <Typography variant='body-s' serif>
              This page includes affiliate links. When you buy through links on
              our site, we may earn an affiliate commission. Tiny Health only
              recommends items that we’ve researched and stand behind.
            </Typography>
          </div>
        </PageContainer>
      </Suspense>
    </>
  ) : (
    <>
      <Header title={`${currentTinyAccount.first_name}’s action plan`} />
      <Suspense fallback={<Spinfinity />}>
        {!!action?.cover_url?.url && (
          <HeaderIllustration
            url={action.cover_url.url}
            bgColor={action.cover_url.color}
          />
        )}
        <PageContainer
          size={ContainerSizes.MD}
          className={styles.pageContainer}
        >
          <DetailHeader action={action} />
          <div className={styles.about}>
            <Markdown>{action.detail}</Markdown>
          </div>
          <div className='mb-2'>
            {!!action?.desired_outcomes?.length && (
              <>
                <h4 className={styles.detailHeading}>Desired Outcome</h4>
                <DesiredOutcomesList outcomes={action?.desired_outcomes} />
              </>
            )}
            <div className='mt-4'>
              <FeedbackWidget
                kitId={currentKitId}
                pageType={FeedbackPageType.ACTION}
                pageId={parseInt(actionId)}
              />
            </div>
            {!!guidesToShow?.length && (
              <>
                <div className={styles.guidesRow}>
                  <Typography
                    variant='heading-l'
                    className={styles.detailHeading}
                  >
                    Related Articles
                  </Typography>
                  <TextLink label='View all' href={`/guides`} />
                </div>
                <div className={styles.guidesGridListContainer}>
                  {guidesToShow}
                </div>
                <div className={styles.guidesSliderContainer}>
                  <CardSlider cards={guidesToShow} />
                </div>
              </>
            )}
            <div className={styles.disclaimers}>
              <Typography variant='body-s' serif>
                Always check with your provider before making any changes to
                your diet or supplement routine. This information is not
                intended to replace professional medical advice.
              </Typography>
              <Typography variant='body-s' serif>
                This page includes affiliate links. When you buy through links
                on our site, we may earn an affiliate commission. Tiny Health
                only recommends items that we’ve researched and stand behind.
              </Typography>
            </div>
          </div>
        </PageContainer>
      </Suspense>
    </>
  );
};
