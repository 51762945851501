//ICON LIBRARY - SPECIAL ICONS
import barsFullSVG from 'assets/images/icon/special/barsFull.svg?react';
import barsMidSVG from 'assets/images/icon/special/barsMid.svg?react';
import barsLowSVG from 'assets/images/icon/special/barsLow.svg?react';
import barsEmptySVG from 'assets/images/icon/special/barsEmpty.svg?react';
import hamburgerSVG from 'assets/images/icon/hamburger.svg?react';

//ICON LIBRARY - OUTLINED ONLY
import carrotSVG from 'assets/images/icon/outlined/carrot.svg?react';
import checkBackgroundSVG from 'assets/images/icon/outlined/check-bg.svg?react';
import chevronBackSVG from 'assets/images/icon/outlined/chevron-back.svg?react';
import chevronDownSVG from 'assets/images/icon/outlined/chevron-down.svg?react';
import chevronForwardSVG from 'assets/images/icon/outlined/chevron-forward.svg?react';
import chevronUpSVG from 'assets/images/icon/outlined/chevron-up.svg?react';
import closeSmSVG from 'assets/images/icon/outlined/close-sm.svg?react';
import collapseSVG from 'assets/images/icon/outlined/collapse.svg?react';
import deliverySVG from 'assets/images/icon/outlined/delivery.svg?react';
import downloadSVG from 'assets/images/icon/outlined/download.svg?react';
import doneSVG from 'assets/images/icon/outlined/done.svg?react';
import downSmallSVG from 'assets/images/icon/outlined/down-small.svg?react';
import expandSVG from 'assets/images/icon/outlined/expand.svg?react';
import filterLinesSVG from 'assets/images/icon/outlined/filter-lines.svg?react';
import leftSmallSVG from 'assets/images/icon/outlined/left-small.svg?react';
import linkExternalSVG from 'assets/images/icon/outlined/link-external.svg?react';
import menuSVG from 'assets/images/icon/outlined/menu.svg?react';
import minusSVG from 'assets/images/icon/outlined/minus.svg?react';
import plusSVG from 'assets/images/icon/outlined/plus.svg?react';
import refreshCcwSVG from 'assets/images/icon/outlined/refresh-ccw.svg?react';
import refreshCwSVG from 'assets/images/icon/outlined/refresh-cw.svg?react';
import rightSmallSVG from 'assets/images/icon/outlined/right-small.svg?react';
import searchSVG from 'assets/images/icon/outlined/search.svg?react';
import shareSVG from 'assets/images/icon/outlined/share.svg?react';
import skipBackSVG from 'assets/images/icon/outlined/skip-back.svg?react';
import skipForwardSVG from 'assets/images/icon/outlined/skip-forward.svg?react';
import upSmallSVG from 'assets/images/icon/outlined/up-small.svg?react';
//ICON LIBRARY - OUTLINED VERSION
import addSVG from 'assets/images/icon/outlined/add.svg?react';
import awardSVG from 'assets/images/icon/outlined/award.svg?react';
import alertCircleSVG from 'assets/images/icon/outlined/alert-circle.svg?react';
import alertTriangleSVG from 'assets/images/icon/outlined/alert-triangle.svg?react';
import atSignSVG from 'assets/images/icon/outlined/at-sign.svg?react';
import babyBottleSVG from 'assets/images/icon/outlined/baby-bottle.svg?react';
import babyFeetSVG from 'assets/images/icon/outlined/baby-feet.svg?react';
import babySVG from 'assets/images/icon/outlined/baby.svg?react';
import bacteriaSVG from 'assets/images/icon/outlined/bacteria.svg?react';
import bandageSVG from 'assets/images/icon/outlined/bandage.svg?react';
import bankNoteSVG from 'assets/images/icon/outlined/bank-note.svg?react';
import barChart01SVG from 'assets/images/icon/outlined/bar-chart-01.svg?react';
import barChart02SVG from 'assets/images/icon/outlined/bar-chart-02.svg?react';
import barChart03SVG from 'assets/images/icon/outlined/bar-chart-03.svg?react';
import barChartCircleSVG from 'assets/images/icon/outlined/bar-chart-circle.svg?react';
import barChartSquare01SVG from 'assets/images/icon/outlined/bar-chart-square-01.svg?react';
import barChartSquare02SVG from 'assets/images/icon/outlined/bar-chart-square-02.svg?react';
import barLineChartSVG from 'assets/images/icon/outlined/bar-line-chart.svg?react';
import beingSickSVG from 'assets/images/icon/outlined/being-sick.svg?react';
import bibSVG from 'assets/images/icon/outlined/bib.svg?react';
import bifidoSVG from 'assets/images/icon/outlined/bifido.svg?react';
import bodyCellsSVG from 'assets/images/icon/outlined/body-cells.svg?react';
import bookSVG from 'assets/images/icon/outlined/book.svg?react';
import bookmarkSVG from 'assets/images/icon/outlined/bookmark.svg?react';
import boxSVG from 'assets/images/icon/outlined/box.svg?react';
import brickSVG from 'assets/images/icon/outlined/brick.svg?react';
import calendarSVG from 'assets/images/icon/outlined/calendar.svg?react';
import chartBreakoutCircleSVG from 'assets/images/icon/outlined/chart-breakout-circle.svg?react';
import chartBreakoutSquareSVG from 'assets/images/icon/outlined/chart-breakout-square.svg?react';
import checkSVG from 'assets/images/icon/outlined/Check.svg?react';
import checkDoneSVG from 'assets/images/icon/outlined/check-done.svg?react';
import checkSquareSVG from 'assets/images/icon/outlined/check-square.svg?react';
import checkVerifiedSVG from 'assets/images/icon/outlined/check-verified.svg?react';
import circleSVG from 'assets/images/icon/outlined/circle.svg?react';
import clamtobacterSVG from 'assets/images/icon/outlined/clamtobacter.svg?react';
import clipboardCheckSVG from 'assets/images/icon/outlined/clipboard-check.svg?react';
import clipboardXSVG from 'assets/images/icon/outlined/clipboard-x.svg?react';
import clipboardSVG from 'assets/images/icon/outlined/clipboard.svg?react';
import clockSVG from 'assets/images/icon/outlined/clock.svg?react';
import copySVG from 'assets/images/icon/outlined/copy.svg?react';
import dnaHelixSVG from 'assets/images/icon/outlined/dna-helix.svg?react';
import doNotDisturbSVG from 'assets/images/icon/outlined/do-not-disturb.svg?react';
import dogPawSVG from 'assets/images/icon/outlined/dog-paw.svg?react';
import eColiSVG from 'assets/images/icon/outlined/e-coli.svg?react';
import edit01SVG from 'assets/images/icon/outlined/edit-01.svg?react';
import edit02SVG from 'assets/images/icon/outlined/edit-02.svg?react';
import enterococcusSVG from 'assets/images/icon/outlined/enterococcus.svg?react';
import eyeOffSVG from 'assets/images/icon/outlined/eye-off.svg?react';
import eyeSVG from 'assets/images/icon/outlined/eye.svg?react';
import faceContentSVG from 'assets/images/icon/outlined/face-content.svg?react';
import faceFrownSVG from 'assets/images/icon/outlined/face-frown.svg?react';
import faceHappySVG from 'assets/images/icon/outlined/face-happy.svg?react';
import faceMaskSVG from 'assets/images/icon/outlined/face-mask.svg?react';
import faceNeutralSVG from 'assets/images/icon/outlined/face-neutral.svg?react';
import faceSadSVG from 'assets/images/icon/outlined/face-sad.svg?react';
import faceSmileSVG from 'assets/images/icon/outlined/face-smile.svg?react';
import faceWinkSVG from 'assets/images/icon/outlined/face-wink.svg?react';
import feedingBabySVG from 'assets/images/icon/outlined/feeding-baby.svg?react';
import fermentedSVG from 'assets/images/icon/outlined/fermented.svg?react';
import file01SVG from 'assets/images/icon/outlined/file-01.svg?react';
import file02SVG from 'assets/images/icon/outlined/file-02.svg?react';
import file03SVG from 'assets/images/icon/outlined/file-03.svg?react';
import file04SVG from 'assets/images/icon/outlined/file-04.svg?react';
import file05SVG from 'assets/images/icon/outlined/file-05.svg?react';
import file06SVG from 'assets/images/icon/outlined/file-06.svg?react';
import fileAttachmentSVG from 'assets/images/icon/outlined/file-attachment.svg?react';
import fileCheck01SVG from 'assets/images/icon/outlined/file-check-01.svg?react';
import fileCheck02SVG from 'assets/images/icon/outlined/file-check-02.svg?react';
import fileHeart01SVG from 'assets/images/icon/outlined/file-heart-01.svg?react';
import fileHeart02SVG from 'assets/images/icon/outlined/file-heart-02.svg?react';
import filePlus01SVG from 'assets/images/icon/outlined/file-plus-01.svg?react';
import filePlus02SVG from 'assets/images/icon/outlined/file-plus-02.svg?react';
import fileQuestion01SVG from 'assets/images/icon/outlined/file-question-01.svg?react';
import fileQuestion02SVG from 'assets/images/icon/outlined/file-question-02.svg?react';
import fileQuestion03SVG from 'assets/images/icon/outlined/file-question-03.svg?react';
import fileSearch01SVG from 'assets/images/icon/outlined/file-search-01.svg?react';
import fileSearch02SVG from 'assets/images/icon/outlined/file-search-02.svg?react';
import flagSVG from 'assets/images/icon/outlined/flag.svg?react';
import flashSVG from 'assets/images/icon/outlined/flash.svg?react';
import flaxSeedsSVG from 'assets/images/icon/outlined/flax-seeds.svg?react';
import giftSVG from 'assets/images/icon/outlined/gift.svg?react';
import groceriesSVG from 'assets/images/icon/outlined/groceries.svg?react';
import heartHealthSVG from 'assets/images/icon/outlined/heart-health.svg?react';
import heartsSVG from 'assets/images/icon/outlined/hearts.svg?react';
import helpSVG from 'assets/images/icon/outlined/help.svg?react';
import highRiskSVG from 'assets/images/icon/outlined/high-risk.svg?react';
import historySVG from 'assets/images/icon/outlined/history.svg?react';
import homeSVG from 'assets/images/icon/outlined/home.svg?react';
import horizontalBarChartSVG from 'assets/images/icon/outlined/horizontal-bar-chart.svg?react';
import housekeepingSVG from 'assets/images/icon/outlined/housekeeping.svg?react';
import infoCircleSVG from 'assets/images/icon/outlined/info-circle.svg?react';
import intestineSVG from 'assets/images/icon/outlined/intestine.svg?react';
import lightOnSVG from 'assets/images/icon/outlined/light-on.svg?react';
import lineChartUp01SVG from 'assets/images/icon/outlined/line-chart-up-01.svg?react';
import lineChartUp02SVG from 'assets/images/icon/outlined/line-chart-up-02.svg?react';
import lineChartUpSVG from 'assets/images/icon/outlined/line-chart-up.svg?react';
import linkSVG from 'assets/images/icon/outlined/link.svg?react';
import loadingSVG from 'assets/images/icon/outlined/loading.svg?react';
import lockSVG from 'assets/images/icon/outlined/lock.svg?react';
import unlockSVG from 'assets/images/icon/outlined/unlock.svg?react';
import logOutSVG from 'assets/images/icon/outlined/log-out.svg?react';
import lotusSVG from 'assets/images/icon/outlined/lotus.svg?react';
import magicWandSVG from 'assets/images/icon/outlined/magic-wand.svg?react';
import mailSVG from 'assets/images/icon/outlined/mail.svg?react';
import markerPin01SVG from 'assets/images/icon/outlined/markerPin01.svg?react';
import markerPin02SVG from 'assets/images/icon/outlined/markerPin02.svg?react';
import messageHearthSquareSVG from 'assets/images/icon/outlined/message-hearth-square.svg?react';
import microbiomeSVG from 'assets/images/icon/outlined/microbiome.svg?react';
import microscopeSVG from 'assets/images/icon/outlined/microscope.svg?react';
import mortarAndPestleSVG from 'assets/images/icon/outlined/mortar-and-pestle.svg?react';
import muscleSVG from 'assets/images/icon/outlined/muscle.svg?react';
import nappySVG from 'assets/images/icon/outlined/nappy.svg?react';
import naturalFoodSVG from 'assets/images/icon/outlined/natural-food.svg?react';
import notificationsSVG from 'assets/images/icon/outlined/notifications.svg?react';
import nutSVG from 'assets/images/icon/outlined/nut.svg?react';
import organicFoodSVG from 'assets/images/icon/outlined/organic-food.svg?react';
import pacifierSVG from 'assets/images/icon/outlined/pacifier.svg?react';
import paperclipSVG from 'assets/images/icon/outlined/paperclip.svg?react';
import pieChart01SVG from 'assets/images/icon/outlined/pie-chart-01.svg?react';
import pieChart02SVG from 'assets/images/icon/outlined/pie-chart-02.svg?react';
import pieChart03SVG from 'assets/images/icon/outlined/pie-chart-03.svg?react';
import pieChart04SVG from 'assets/images/icon/outlined/pie-chart-04.svg?react';
import pillSVG from 'assets/images/icon/outlined/pill.svg?react';
import pillsSVG from 'assets/images/icon/outlined/pills.svg?react';
import pregnantSVG from 'assets/images/icon/outlined/pregnant.svg?react';
import prenatalYogaSVG from 'assets/images/icon/outlined/prenatal-yoga.svg?react';
import priceTagSVG from 'assets/images/icon/outlined/price-tag.svg?react';
import printerSVG from 'assets/images/icon/outlined/printer.svg?react';
import recipeSVG from 'assets/images/icon/outlined/recipe.svg?react';
import repeatSVG from 'assets/images/icon/outlined/repeat.svg?react';
import sadGhostSVG from 'assets/images/icon/outlined/sad-ghost.svg?react';
import saleSVG from 'assets/images/icon/outlined/sale.svg?react';
import scaleSVG from 'assets/images/icon/outlined/scale.svg?react';
import seedingSVG from 'assets/images/icon/outlined/seeding.svg?react';
import sendSVG from 'assets/images/icon/outlined/send.svg?react';
import settingsSVG from 'assets/images/icon/outlined/settings.svg?react';
import sheepSVG from 'assets/images/icon/outlined/sheep.svg?react';
import shoppingCartSVG from 'assets/images/icon/outlined/shopping-cart.svg?react';
import spoon01SVG from 'assets/images/icon/outlined/spoon-01.svg?react';
import spoon02SVG from 'assets/images/icon/outlined/spoon-02.svg?react';
import starSVG from 'assets/images/icon/outlined/star.svg?react';
import stars01SVG from 'assets/images/icon/outlined/stars-01.svg?react';
import stars02SVG from 'assets/images/icon/outlined/stars-02.svg?react';
import stars03SVG from 'assets/images/icon/outlined/stars-03.svg?react';
import stethoscopeSVG from 'assets/images/icon/outlined/stethoscope.svg?react';
import stickerCircleSVG from 'assets/images/icon/outlined/sticker-circle.svg?react';
import stickerSquareSVG from 'assets/images/icon/outlined/sticker-square.svg?react';
import stomachSVG from 'assets/images/icon/outlined/stomach.svg?react';
import swabSVG from 'assets/images/icon/outlined/swab.svg?react';
import syringeSVG from 'assets/images/icon/outlined/syringe.svg?react';
import targetSVG from 'assets/images/icon/outlined/target.svg?react';
import todaySVG from 'assets/images/icon/outlined/today.svg?react';
import toolSVG from 'assets/images/icon/outlined/tool.svg?react';
import trashSVG from 'assets/images/icon/outlined/trash.svg?react';
import thumbsDownSVG from 'assets/images/icon/outlined/thumbs-down.svg?react';
import thumbsUpSVG from 'assets/images/icon/outlined/thumbs-up.svg?react';
import trendDownSVG from 'assets/images/icon/outlined/trend-down.svg?react';
import trendUp01SVG from 'assets/images/icon/outlined/trend-up-01.svg?react';
import trendUp02SVG from 'assets/images/icon/outlined/trend-up-02.svg?react';
import trophySVG from 'assets/images/icon/outlined/trophy.svg?react';
import userCheckSVG from 'assets/images/icon/outlined/user-check.svg?react';
import userEditSVG from 'assets/images/icon/outlined/user-edit.svg?react';
import userMultipleSVG from 'assets/images/icon/outlined/user-multiple.svg?react';
import userSingleSVG from 'assets/images/icon/outlined/user-single.svg?react';
import virusSVG from 'assets/images/icon/outlined/virus.svg?react';
import zapCircleSVG from 'assets/images/icon/outlined/zap-circle.svg?react';
import zapSquareSVG from 'assets/images/icon/outlined/zap-square.svg?react';

//ICON LIBRARY - FILLED VERSION
import addSVGFilled from 'assets/images/icon/filled/add.svg?react';
import awardSVGFilled from 'assets/images/icon/outlined/award.svg?react';
import alertCircleSVGFilled from 'assets/images/icon/filled/alert-circle.svg?react';
import alertTriangleSVGFilled from 'assets/images/icon/filled/alert-triangle.svg?react';
import alertTriangleDoubleSVGFilled from 'assets/images/icon/filled/alert-triangle-double.svg?react';
import atSignSVGFilled from 'assets/images/icon/filled/at-sign.svg?react';
import babyBottleSVGFilled from 'assets/images/icon/filled/baby-bottle.svg?react';
import babyFeetSVGFilled from 'assets/images/icon/filled/baby-feet.svg?react';
import babySVGFilled from 'assets/images/icon/filled/baby.svg?react';
import bacteriaSVGFilled from 'assets/images/icon/filled/bacteria.svg?react';
import bandageSVGFilled from 'assets/images/icon/filled/bandage.svg?react';
import bankNoteSVGFilled from 'assets/images/icon/filled/bank-note.svg?react';
import barChart01SVGFilled from 'assets/images/icon/filled/bar-chart-01.svg?react';
import barChart02SVGFilled from 'assets/images/icon/filled/bar-chart-02.svg?react';
import barChart03SVGFilled from 'assets/images/icon/filled/bar-chart-03.svg?react';
import barChartCircleSVGFilled from 'assets/images/icon/filled/bar-chart-circle.svg?react';
import barChartSquare01SVGFilled from 'assets/images/icon/filled/bar-chart-square-01.svg?react';
import barChartSquare02SVGFilled from 'assets/images/icon/filled/bar-chart-square-02.svg?react';
import barLineChartSVGFilled from 'assets/images/icon/filled/bar-line-chart.svg?react';
import beingSickSVGFilled from 'assets/images/icon/filled/being-sick.svg?react';
import bibSVGFilled from 'assets/images/icon/filled/bib.svg?react';
import bifidoSVGFilled from 'assets/images/icon/filled/bifido.svg?react';
import bodyCellsSVGFilled from 'assets/images/icon/filled/body-cells.svg?react';
import bookSVGFilled from 'assets/images/icon/filled/book.svg?react';
import bookmarkSVGFilled from 'assets/images/icon/filled/bookmark.svg?react';
import boxSVGFilled from 'assets/images/icon/filled/box.svg?react';
import brickSVGFilled from 'assets/images/icon/filled/brick.svg?react';
import calendarSVGFilled from 'assets/images/icon/filled/calendar.svg?react';
import copySVGFilled from 'assets/images/icon/filled/copy.svg?react';
import chartBreakoutCircleSVGFilled from 'assets/images/icon/filled/chart-breakout-circle.svg?react';
import chartBreakoutSquareSVGFilled from 'assets/images/icon/filled/chart-breakout-square.svg?react';
import checkSVGFilled from 'assets/images/icon/filled/check.svg?react';
import checkDoneSVGFilled from 'assets/images/icon/filled/check-done.svg?react';
import checkSquareSVGFilled from 'assets/images/icon/filled/check-square.svg?react';
import checkVerifiedSVGFilled from 'assets/images/icon/filled/check-verified.svg?react';
import circleSVGFilled from 'assets/images/icon/filled/circle.svg?react';
import clamtobacterSVGFilled from 'assets/images/icon/filled/clamtobacter.svg?react';
import clipboardCheckSVGFilled from 'assets/images/icon/filled/clipboard-check.svg?react';
import clipboardXSVGFilled from 'assets/images/icon/filled/clipboard-x.svg?react';
import clipboardSVGFilled from 'assets/images/icon/filled/clipboard.svg?react';
import clockSVGFilled from 'assets/images/icon/filled/clock.svg?react';
import deliverySVGFilled from 'assets/images/icon/filled/delivery.svg?react';
import dnaHelixSVGFilled from 'assets/images/icon/filled/dna-helix.svg?react';
import doNotDisturbSVGFilled from 'assets/images/icon/filled/do-not-disturb.svg?react';
import dogPawSVGFilled from 'assets/images/icon/filled/dog-paw.svg?react';
import eColiSVGFilled from 'assets/images/icon/filled/e-coli.svg?react';
import edit01SVGFilled from 'assets/images/icon/filled/edit-01.svg?react';
import edit02SVGFilled from 'assets/images/icon/filled/edit-02.svg?react';
import enterococcusSVGFilled from 'assets/images/icon/filled/enterococcus.svg?react';
import eyeOffSVGFilled from 'assets/images/icon/filled/eye-off.svg?react';
import eyeSVGFilled from 'assets/images/icon/filled/eye.svg?react';
import faceContentSVGFilled from 'assets/images/icon/filled/face-content.svg?react';
import faceFrownSVGFilled from 'assets/images/icon/filled/face-frown.svg?react';
import faceHappySVGFilled from 'assets/images/icon/filled/face-happy.svg?react';
import faceMaskSVGFilled from 'assets/images/icon/filled/face-mask.svg?react';
import faceNeutralSVGFilled from 'assets/images/icon/filled/face-neutral.svg?react';
import faceSadSVGFilled from 'assets/images/icon/filled/face-sad.svg?react';
import faceSmileSVGFilled from 'assets/images/icon/filled/face-smile.svg?react';
import faceWinkSVGFilled from 'assets/images/icon/filled/face-wink.svg?react';
import feedingBabySVGFilled from 'assets/images/icon/filled/feeding-baby.svg?react';
import fermentedSVGFilled from 'assets/images/icon/filled/fermented.svg?react';
import file01SVGFilled from 'assets/images/icon/filled/file-01.svg?react';
import file02SVGFilled from 'assets/images/icon/filled/file-02.svg?react';
import file03SVGFilled from 'assets/images/icon/filled/file-03.svg?react';
import file04SVGFilled from 'assets/images/icon/filled/file-04.svg?react';
import file05SVGFilled from 'assets/images/icon/filled/file-05.svg?react';
import file06SVGFilled from 'assets/images/icon/filled/file-06.svg?react';
import fileAttachmentSVGFilled from 'assets/images/icon/filled/file-attachment.svg?react';
import fileCheck01SVGFilled from 'assets/images/icon/filled/file-check-01.svg?react';
import fileCheck02SVGFilled from 'assets/images/icon/filled/file-check-02.svg?react';
import fileHeart01SVGFilled from 'assets/images/icon/filled/file-heart-01.svg?react';
import fileHeart02SVGFilled from 'assets/images/icon/filled/file-heart-02.svg?react';
import filePlus01SVGFilled from 'assets/images/icon/filled/file-plus-01.svg?react';
import filePlus02SVGFilled from 'assets/images/icon/filled/file-plus-02.svg?react';
import fileQuestion01SVGFilled from 'assets/images/icon/filled/file-question-01.svg?react';
import fileQuestion02SVGFilled from 'assets/images/icon/filled/file-question-02.svg?react';
import fileQuestion03SVGFilled from 'assets/images/icon/filled/file-question-03.svg?react';
import fileSearch01SVGFilled from 'assets/images/icon/filled/file-search-01.svg?react';
import fileSearch02SVGFilled from 'assets/images/icon/filled/file-search-02.svg?react';
import flagSVGFilled from 'assets/images/icon/filled/flag.svg?react';
import flashSVGFilled from 'assets/images/icon/filled/flash.svg?react';
import flaxSeedsSVGFilled from 'assets/images/icon/filled/flax-seeds.svg?react';
import giftSVGFilled from 'assets/images/icon/filled/gift.svg?react';
import groceriesSVGFilled from 'assets/images/icon/filled/groceries.svg?react';
import heartHealthSVGFilled from 'assets/images/icon/filled/heart-health.svg?react';
import heartsSVGFilled from 'assets/images/icon/filled/hearts.svg?react';
import helpSVGFilled from 'assets/images/icon/filled/help.svg?react';
import highRiskSVGFilled from 'assets/images/icon/filled/high-risk.svg?react';
import historySVGFilled from 'assets/images/icon/filled/history.svg?react';
import homeSVGFilled from 'assets/images/icon/filled/home.svg?react';
import horizontalBarChartSVGFilled from 'assets/images/icon/filled/horizontal-bar-chart.svg?react';
import housekeepingSVGFilled from 'assets/images/icon/filled/housekeeping.svg?react';
import infoCircleSVGFilled from 'assets/images/icon/filled/info-circle.svg?react';
import intestineSVGFilled from 'assets/images/icon/filled/intestine.svg?react';
import lightOnSVGFilled from 'assets/images/icon/filled/light-on.svg?react';
import lineChartUp01SVGFilled from 'assets/images/icon/filled/line-chart-up-01.svg?react';
import lineChartUp02SVGFilled from 'assets/images/icon/filled/line-chart-up-02.svg?react';
import lineChartUpSVGFilled from 'assets/images/icon/filled/line-chart-up.svg?react';
import linkSVGFilled from 'assets/images/icon/filled/link.svg?react';
import loadingSVGFilled from 'assets/images/icon/filled/loading.svg?react';
import lockSVGFilled from 'assets/images/icon/filled/lock.svg?react';
import logOutSVGFilled from 'assets/images/icon/filled/log-out.svg?react';
import lotusSVGFilled from 'assets/images/icon/filled/lotus.svg?react';
import magicWandSVGFilled from 'assets/images/icon/filled/magic-wand.svg?react';
import mailSVGFilled from 'assets/images/icon/filled/mail.svg?react';
import markerPin01SVGFilled from 'assets/images/icon/filled/markerPin01.svg?react';
import markerPin02SVGFilled from 'assets/images/icon/filled/markerPin02.svg?react';
import messageHearthSquareSVGFilled from 'assets/images/icon/filled/message-hearth-square.svg?react';
import microbiomeSVGFilled from 'assets/images/icon/filled/microbiome.svg?react';
import microscopeSVGFilled from 'assets/images/icon/filled/microscope.svg?react';
import mortarAndPestleSVGFilled from 'assets/images/icon/filled/mortar-and-pestle.svg?react';
import muscleSVGFilled from 'assets/images/icon/filled/muscle.svg?react';
import nappySVGFilled from 'assets/images/icon/filled/nappy.svg?react';
import naturalFoodSVGFilled from 'assets/images/icon/filled/natural-food.svg?react';
import notificationsSVGFilled from 'assets/images/icon/filled/notifications.svg?react';
import nutSVGFilled from 'assets/images/icon/filled/nut.svg?react';
import organicFoodSVGFilled from 'assets/images/icon/filled/organic-food.svg?react';
import pacifierSVGFilled from 'assets/images/icon/filled/pacifier.svg?react';
import paperclipSVGFilled from 'assets/images/icon/filled/paperclip.svg?react';
import pieChart01SVGFilled from 'assets/images/icon/filled/pie-chart-01.svg?react';
import pieChart02SVGFilled from 'assets/images/icon/filled/pie-chart-02.svg?react';
import pieChart03SVGFilled from 'assets/images/icon/filled/pie-chart-03.svg?react';
import pieChart04SVGFilled from 'assets/images/icon/filled/pie-chart-04.svg?react';
import pillSVGFilled from 'assets/images/icon/filled/pill.svg?react';
import pillsSVGFilled from 'assets/images/icon/filled/pills.svg?react';
import pregnantSVGFilled from 'assets/images/icon/filled/pregnant.svg?react';
import prenatalYogaSVGFilled from 'assets/images/icon/filled/prenatal-yoga.svg?react';
import priceTagSVGFilled from 'assets/images/icon/filled/price-tag.svg?react';
import printerSVGFilled from 'assets/images/icon/filled/printer.svg?react';
import recipeSVGFilled from 'assets/images/icon/filled/recipe.svg?react';
import repeatSVGFilled from 'assets/images/icon/filled/repeat.svg?react';
import sadGhostSVGFilled from 'assets/images/icon/filled/sad-ghost.svg?react';
import saleSVGFilled from 'assets/images/icon/filled/sale.svg?react';
import scaleSVGFilled from 'assets/images/icon/filled/scale.svg?react';
import seedingSVGFilled from 'assets/images/icon/filled/seeding.svg?react';
import sendSVGFilled from 'assets/images/icon/filled/send.svg?react';
import settingsSVGFilled from 'assets/images/icon/filled/settings.svg?react';
import sheepSVGFilled from 'assets/images/icon/filled/sheep.svg?react';
import shoppingCartSVGFilled from 'assets/images/icon/filled/shopping-cart.svg?react';
import spoon01SVGFilled from 'assets/images/icon/filled/spoon-01.svg?react';
import spoon02SVGFilled from 'assets/images/icon/filled/spoon-02.svg?react';
import starSVGFilled from 'assets/images/icon/filled/star.svg?react';
import stars01SVGFilled from 'assets/images/icon/filled/stars-01.svg?react';
import stars02SVGFilled from 'assets/images/icon/filled/stars-02.svg?react';
import stars03SVGFilled from 'assets/images/icon/filled/stars-03.svg?react';
import stethoscopeSVGFilled from 'assets/images/icon/filled/stethoscope.svg?react';
import stickerCircleSVGFilled from 'assets/images/icon/filled/sticker-circle.svg?react';
import stickerSquareSVGFilled from 'assets/images/icon/filled/sticker-square.svg?react';
import stomachSVGFilled from 'assets/images/icon/filled/stomach.svg?react';
import swabSVGFilled from 'assets/images/icon/filled/swab.svg?react';
import syringeSVGFilled from 'assets/images/icon/filled/syringe.svg?react';
import targetSVGFilled from 'assets/images/icon/filled/target.svg?react';
import todaySVGFilled from 'assets/images/icon/filled/today.svg?react';
import toolSVGFilled from 'assets/images/icon/filled/tool.svg?react';
import trashSVGFilled from 'assets/images/icon/filled/trash.svg?react';
import thumbsDownSVGFilled from 'assets/images/icon/filled/thumbs-down.svg?react';
import thumbsUpSVGFilled from 'assets/images/icon/filled/thumbs-up.svg?react';
import trendDownSVGFilled from 'assets/images/icon/filled/trend-down.svg?react';
import trendUp01SVGFilled from 'assets/images/icon/filled/trend-up-01.svg?react';
import trendUp02SVGFilled from 'assets/images/icon/filled/trend-up-02.svg?react';
import trophySVGFilled from 'assets/images/icon/filled/trophy.svg?react';
import userCheckSVGFilled from 'assets/images/icon/filled/user-check.svg?react';
import userEditSVGFilled from 'assets/images/icon/filled/user-edit.svg?react';
import userMultipleSVGFilled from 'assets/images/icon/filled/user-multiple.svg?react';
import userSingleSVGFilled from 'assets/images/icon/filled/user-single.svg?react';
import virusSVGFilled from 'assets/images/icon/filled/virus.svg?react';
import zapCircleSVGFilled from 'assets/images/icon/filled/zap-circle.svg?react';
import zapSquareSVGFilled from 'assets/images/icon/filled/zap-square.svg?react';

import NumberBlocksSVG from 'assets/images/icon/numberBlocks.svg?react';
import LetterBlocksSVG from 'assets/images/icon/letterBlocks.svg?react';

export const ICON_SVG = {
  // down from here is the complete list of the icons in the Figma Icon Library (SPECIAL ONLY)
  barsFull: barsFullSVG,
  barsMid: barsMidSVG,
  barsLow: barsLowSVG,
  barsEmpty: barsEmptySVG,
  hamburger: hamburgerSVG,
  checkBackground: checkBackgroundSVG,
  // down from here is the complete list of the icons in the Figma Icon Library (OUTLINED ONLY)
  carrot: carrotSVG,
  chevronBack: chevronBackSVG,
  chevronDown: chevronDownSVG,
  chevronForward: chevronForwardSVG,
  chevronUp: chevronUpSVG,
  closeSm: closeSmSVG,
  collapse: collapseSVG,
  copy: copySVG,
  done: doneSVG,
  downSmall: downSmallSVG,
  expand: expandSVG,
  filterLines: filterLinesSVG,
  leftSmall: leftSmallSVG,
  linkExternal: linkExternalSVG,
  menu: menuSVG,
  minus: minusSVG,
  plus: plusSVG,
  refreshCcw: refreshCcwSVG,
  refreshCw: refreshCwSVG,
  rightSmall: rightSmallSVG,
  upSmall: upSmallSVG,
  numberBlocks: NumberBlocksSVG,
  letterBlocks: LetterBlocksSVG,
  search: searchSVG,
  share: shareSVG,
  skipBack: skipBackSVG,
  skipForward: skipForwardSVG,

  // down from here is the complete list of the icons in the Figma Icon Library (OUTLINED VERSION)
  add: addSVG,
  award: awardSVG,
  alertCircle: alertCircleSVG,
  alertTriangle: alertTriangleSVG,
  atSign: atSignSVG,
  babyBottle: babyBottleSVG,
  babyFeet: babyFeetSVG,
  baby: babySVG,
  bacteria: bacteriaSVG,
  bandage: bandageSVG,
  bankNote: bankNoteSVG,
  barChart01: barChart01SVG,
  barChart02: barChart02SVG,
  barChart03: barChart03SVG,
  barChartCircle: barChartCircleSVG,
  barChartSquare01: barChartSquare01SVG,
  barChartSquare02: barChartSquare02SVG,
  barLineChart: barLineChartSVG,
  beingSick: beingSickSVG,
  bib: bibSVG,
  bifido: bifidoSVG,
  bodyCells: bodyCellsSVG,
  book: bookSVG,
  bookmark: bookmarkSVG,
  box: boxSVG,
  brick: brickSVG,
  calendar: calendarSVG,
  chartBreakoutCircle: chartBreakoutCircleSVG,
  chartBreakoutSquare: chartBreakoutSquareSVG,
  check: checkSVG,
  checkDone: checkDoneSVG,
  checkSquare: checkSquareSVG,
  checkVerified: checkVerifiedSVG,
  circle: circleSVG,
  clamtobacter: clamtobacterSVG,
  clipboardCheck: clipboardCheckSVG,
  clipboardX: clipboardXSVG,
  clipboard: clipboardSVG,
  clock: clockSVG,
  delivery: deliverySVG,
  download: downloadSVG,
  dnaHelix: dnaHelixSVG,
  doNotDisturb: doNotDisturbSVG,
  dogPaw: dogPawSVG,
  eColi: eColiSVG,
  edit01: edit01SVG,
  edit02: edit02SVG,
  enterococcus: enterococcusSVG,
  eyeOff: eyeOffSVG,
  eye: eyeSVG,
  faceContent: faceContentSVG,
  faceFrown: faceFrownSVG,
  faceHappy: faceHappySVG,
  faceMask: faceMaskSVG,
  faceNeutral: faceNeutralSVG,
  faceSad: faceSadSVG,
  faceSmile: faceSmileSVG,
  faceWink: faceWinkSVG,
  feedingBaby: feedingBabySVG,
  fermented: fermentedSVG,
  file01: file01SVG,
  file02: file02SVG,
  file03: file03SVG,
  file04: file04SVG,
  file05: file05SVG,
  file06: file06SVG,
  fileAttachment: fileAttachmentSVG,
  fileCheck01: fileCheck01SVG,
  fileCheck02: fileCheck02SVG,
  fileHeart01: fileHeart01SVG,
  fileHeart02: fileHeart02SVG,
  filePlus01: filePlus01SVG,
  filePlus02: filePlus02SVG,
  fileQuestion01: fileQuestion01SVG,
  fileQuestion02: fileQuestion02SVG,
  fileQuestion03: fileQuestion03SVG,
  fileSearch01: fileSearch01SVG,
  fileSearch02: fileSearch02SVG,
  flag: flagSVG,
  flash: flashSVG,
  flaxSeeds: flaxSeedsSVG,
  gift: giftSVG,
  groceries: groceriesSVG,
  heartHealth: heartHealthSVG,
  hearts: heartsSVG,
  help: helpSVG,
  highRisk: highRiskSVG,
  history: historySVG,
  home: homeSVG,
  horizontalBarChart: horizontalBarChartSVG,
  housekeeping: housekeepingSVG,
  infoCircle: infoCircleSVG,
  intestine: intestineSVG,
  lightOn: lightOnSVG,
  lineChartUp01: lineChartUp01SVG,
  lineChartUp02: lineChartUp02SVG,
  lineChartUp: lineChartUpSVG,
  link: linkSVG,
  loading: loadingSVG,
  lock: lockSVG,
  unlock: unlockSVG,
  logOut: logOutSVG,
  lotus: lotusSVG,
  magicWand: magicWandSVG,
  mail: mailSVG,
  markerPin01: markerPin01SVG,
  markerPin02: markerPin02SVG,
  messageHearthSquare: messageHearthSquareSVG,
  microbiome: microbiomeSVG,
  microscope: microscopeSVG,
  mortarAndPestle: mortarAndPestleSVG,
  muscle: muscleSVG,
  nappy: nappySVG,
  naturalFood: naturalFoodSVG,
  notifications: notificationsSVG,
  nut: nutSVG,
  organicFood: organicFoodSVG,
  pacifier: pacifierSVG,
  paperclip: paperclipSVG,
  pieChart01: pieChart01SVG,
  pieChart02: pieChart02SVG,
  pieChart03: pieChart03SVG,
  pieChart04: pieChart04SVG,
  pill: pillSVG,
  pills: pillsSVG,
  pregnant: pregnantSVG,
  prenatalYoga: prenatalYogaSVG,
  priceTag: priceTagSVG,
  printer: printerSVG,
  recipe: recipeSVG,
  repeat: repeatSVG,
  sadGhost: sadGhostSVG,
  sale: saleSVG,
  scale: scaleSVG,
  seeding: seedingSVG,
  send: sendSVG,
  settings: settingsSVG,
  sheep: sheepSVG,
  shoppingCart: shoppingCartSVG,
  star: starSVG,
  spoon01: spoon01SVG,
  spoon02: spoon02SVG,
  stars01: stars01SVG,
  stars02: stars02SVG,
  stars03: stars03SVG,
  stethoscope: stethoscopeSVG,
  stickerCircle: stickerCircleSVG,
  stickerSquare: stickerSquareSVG,
  stomach: stomachSVG,
  swab: swabSVG,
  syringe: syringeSVG,
  target: targetSVG,
  today: todaySVG,
  tool: toolSVG,
  trash: trashSVG,
  thumbsDown: thumbsDownSVG,
  thumbsUp: thumbsUpSVG,
  trendDown: trendDownSVG,
  trendUp01: trendUp01SVG,
  trendUp02: trendUp02SVG,
  trophy: trophySVG,
  userCheck: userCheckSVG,
  userEdit: userEditSVG,
  userMultiple: userMultipleSVG,
  userSingle: userSingleSVG,
  virus: virusSVG,
  zapCircle: zapCircleSVG,
  zapSquare: zapSquareSVG,

  // down from here is the complete list of the icons in the Figma Icon Library (FILLED VERSION)
  addFilled: addSVGFilled,
  awardFilled: awardSVGFilled,
  alertCircleFilled: alertCircleSVGFilled,
  alertTriangleFilled: alertTriangleSVGFilled,
  alertTriangleDoubleFilled: alertTriangleDoubleSVGFilled,
  atSignFilled: atSignSVGFilled,
  babyBottleFilled: babyBottleSVGFilled,
  babyFeetFilled: babyFeetSVGFilled,
  babyFilled: babySVGFilled,
  bacteriaFilled: bacteriaSVGFilled,
  bandageFilled: bandageSVGFilled,
  bankNoteFilled: bankNoteSVGFilled,
  barChart01Filled: barChart01SVGFilled,
  barChart02Filled: barChart02SVGFilled,
  barChart03Filled: barChart03SVGFilled,
  barChartCircleFilled: barChartCircleSVGFilled,
  barChartSquare01Filled: barChartSquare01SVGFilled,
  barChartSquare02Filled: barChartSquare02SVGFilled,
  barLineChartFilled: barLineChartSVGFilled,
  beingSickFilled: beingSickSVGFilled,
  bibFilled: bibSVGFilled,
  bifidoFilled: bifidoSVGFilled,
  bodyCellsFilled: bodyCellsSVGFilled,
  bookFilled: bookSVGFilled,
  bookmarkFilled: bookmarkSVGFilled,
  boxFilled: boxSVGFilled,
  brickFilled: brickSVGFilled,
  calendarFilled: calendarSVGFilled,
  chartBreakoutCircleFilled: chartBreakoutCircleSVGFilled,
  chartBreakoutSquareFilled: chartBreakoutSquareSVGFilled,
  checkFilled: checkSVGFilled,
  checkDoneFilled: checkDoneSVGFilled,
  checkSquareFilled: checkSquareSVGFilled,
  checkVerifiedFilled: checkVerifiedSVGFilled,
  circleFilled: circleSVGFilled,
  clamtobacterFilled: clamtobacterSVGFilled,
  clipboardCheckFilled: clipboardCheckSVGFilled,
  clipboardXFilled: clipboardXSVGFilled,
  clipboardFilled: clipboardSVGFilled,
  clockFilled: clockSVGFilled,
  copySVGFilled: copySVGFilled,
  deliveryFilled: deliverySVGFilled,
  dnaHelixFilled: dnaHelixSVGFilled,
  doNotDisturbFilled: doNotDisturbSVGFilled,
  dogPawFilled: dogPawSVGFilled,
  eColiFilled: eColiSVGFilled,
  edit01Filled: edit01SVGFilled,
  edit02Filled: edit02SVGFilled,
  enterococcusFilled: enterococcusSVGFilled,
  eyeOffFilled: eyeOffSVGFilled,
  eyeFilled: eyeSVGFilled,
  faceContentFilled: faceContentSVGFilled,
  faceFrownFilled: faceFrownSVGFilled,
  faceHappyFilled: faceHappySVGFilled,
  faceMaskFilled: faceMaskSVGFilled,
  faceNeutralFilled: faceNeutralSVGFilled,
  faceSadFilled: faceSadSVGFilled,
  faceSmileFilled: faceSmileSVGFilled,
  faceWinkFilled: faceWinkSVGFilled,
  feedingBabyFilled: feedingBabySVGFilled,
  fermentedFilled: fermentedSVGFilled,
  file01Filled: file01SVGFilled,
  file02Filled: file02SVGFilled,
  file03Filled: file03SVGFilled,
  file04Filled: file04SVGFilled,
  file05Filled: file05SVGFilled,
  file06Filled: file06SVGFilled,
  fileAttachmentFilled: fileAttachmentSVGFilled,
  fileCheck01Filled: fileCheck01SVGFilled,
  fileCheck02Filled: fileCheck02SVGFilled,
  fileHeart01Filled: fileHeart01SVGFilled,
  fileHeart02Filled: fileHeart02SVGFilled,
  filePlus01Filled: filePlus01SVGFilled,
  filePlus02Filled: filePlus02SVGFilled,
  fileQuestion01Filled: fileQuestion01SVGFilled,
  fileQuestion02Filled: fileQuestion02SVGFilled,
  fileQuestion03Filled: fileQuestion03SVGFilled,
  fileSearch01Filled: fileSearch01SVGFilled,
  fileSearch02Filled: fileSearch02SVGFilled,
  flagFilled: flagSVGFilled,
  flashFilled: flashSVGFilled,
  flaxSeedsFilled: flaxSeedsSVGFilled,
  giftFilled: giftSVGFilled,
  groceriesFilled: groceriesSVGFilled,
  heartHealthFilled: heartHealthSVGFilled,
  heartsFilled: heartsSVGFilled,
  helpFilled: helpSVGFilled,
  highRiskFilled: highRiskSVGFilled,
  historyFilled: historySVGFilled,
  homeFilled: homeSVGFilled,
  horizontalBarChartFilled: horizontalBarChartSVGFilled,
  housekeepingFilled: housekeepingSVGFilled,
  infoCircleFilled: infoCircleSVGFilled,
  intestineFilled: intestineSVGFilled,
  lightOnFilled: lightOnSVGFilled,
  lineChartUp01Filled: lineChartUp01SVGFilled,
  lineChartUp02Filled: lineChartUp02SVGFilled,
  lineChartUpFilled: lineChartUpSVGFilled,
  linkFilled: linkSVGFilled,
  loadingFilled: loadingSVGFilled,
  lockFilled: lockSVGFilled,
  logOutFilled: logOutSVGFilled,
  lotusFilled: lotusSVGFilled,
  magicWandFilled: magicWandSVGFilled,
  mailFilled: mailSVGFilled,
  markerPin01Filled: markerPin01SVGFilled,
  markerPin02Filled: markerPin02SVGFilled,
  messageHearthSquareFilled: messageHearthSquareSVGFilled,
  microbiomeFilled: microbiomeSVGFilled,
  microscopeFilled: microscopeSVGFilled,
  mortarAndPestleFilled: mortarAndPestleSVGFilled,
  muscleFilled: muscleSVGFilled,
  nappyFilled: nappySVGFilled,
  naturalFoodFilled: naturalFoodSVGFilled,
  notificationsFilled: notificationsSVGFilled,
  nutFilled: nutSVGFilled,
  organicFoodFilled: organicFoodSVGFilled,
  pacifierFilled: pacifierSVGFilled,
  paperclipFilled: paperclipSVGFilled,
  pieChart01Filled: pieChart01SVGFilled,
  pieChart02Filled: pieChart02SVGFilled,
  pieChart03Filled: pieChart03SVGFilled,
  pieChart04Filled: pieChart04SVGFilled,
  pillFilled: pillSVGFilled,
  pillsFilled: pillsSVGFilled,
  pregnantFilled: pregnantSVGFilled,
  prenatalYogaFilled: prenatalYogaSVGFilled,
  priceTagFilled: priceTagSVGFilled,
  printerFilled: printerSVGFilled,
  recipeFilled: recipeSVGFilled,
  repeatFilled: repeatSVGFilled,
  sadGhostFilled: sadGhostSVGFilled,
  saleFilled: saleSVGFilled,
  scaleFilled: scaleSVGFilled,
  seedingFilled: seedingSVGFilled,
  sendFilled: sendSVGFilled,
  settingsFilled: settingsSVGFilled,
  sheepFilled: sheepSVGFilled,
  shoppingCartFilled: shoppingCartSVGFilled,
  starFilled: starSVGFilled,
  spoon01Filled: spoon01SVGFilled,
  spoon02Filled: spoon02SVGFilled,
  stars01Filled: stars01SVGFilled,
  stars02Filled: stars02SVGFilled,
  stars03Filled: stars03SVGFilled,
  stethoscopeFilled: stethoscopeSVGFilled,
  stickerCircleFilled: stickerCircleSVGFilled,
  stickerSquareFilled: stickerSquareSVGFilled,
  stomachFilled: stomachSVGFilled,
  swabFilled: swabSVGFilled,
  syringeFilled: syringeSVGFilled,
  targetFilled: targetSVGFilled,
  todayFilled: todaySVGFilled,
  toolFilled: toolSVGFilled,
  trashFilled: trashSVGFilled,
  thumbsDownFilled: thumbsDownSVGFilled,
  thumbsUpFilled: thumbsUpSVGFilled,
  trendDownFilled: trendDownSVGFilled,
  trendUp01Filled: trendUp01SVGFilled,
  trendUp02Filled: trendUp02SVGFilled,
  trophyFilled: trophySVGFilled,
  userCheckFilled: userCheckSVGFilled,
  userEditFilled: userEditSVGFilled,
  userMultipleFilled: userMultipleSVGFilled,
  userSingleFilled: userSingleSVGFilled,
  virusFilled: virusSVGFilled,
  zapCircleFilled: zapCircleSVGFilled,
  zapSquareFilled: zapSquareSVGFilled,
};
export type IconName = keyof typeof ICON_SVG;
export const getSvgByName = (name: IconName) => {
  return ICON_SVG[name]
    ? ICON_SVG[name]
    : ICON_SVG['microbiomeFilled'] ??
        ({} as React.FC<React.SVGProps<SVGSVGElement>>);
};
