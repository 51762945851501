import { Icon, Modal, Typography } from 'components';

import styles from './SurveysInfoModal.module.scss';

export const SurveysInfoModal = () => {
  return (
    <Modal
      showHeader={true}
      title='What do we use surveys for?'
      label={
        <div className='flex align-items-center gap-1'>
          <Typography variant='body-s'>Next steps</Typography>
          <Icon name='alertCircle' size='xs' />
        </div>
      }
      labelClassName={styles.modalButton}
    >
      <div>
        <Typography variant='heading-m'>
          Thanks for taking the time to answer these surveys. Your answers are
          critical to generating your report. Here are some of the things we use
          this data for:
        </Typography>
        <ol className='flex flex-column gap-2 pl-4 mt-2 grey-text'>
          <li>
            <Typography variant='body-s' serif>
              Developing new insights and updating existing ones: We’re
              constantly updating our insights and reports. As we release new
              insights, we’ll notify you of any that are relevant to you.
            </Typography>
          </li>
          <li>
            <Typography variant='body-s' serif>
              To customize your Action Plan: we provide specific actions based
              on your test results and your survey answers.
            </Typography>
          </li>
          <li>
            <Typography variant='body-s' serif>
              To provide an Expert Highlight summary for you: part of your
              report includes a summary of your results and select answers from
              surveys. Note: as our team continues to enhance the summary, more
              details from surveys will be incorporated.
            </Typography>
          </li>
          <li>
            <Typography variant='body-s' serif>
              For consults: if you book a coaching session with one of our
              microbiome experts, they will review your file and better able to
              help you interpret nuances in your report.
            </Typography>
          </li>
          <li>
            <Typography variant='body-s' serif>
              For research: core to our mission is to push the boundaries of
              microbiome understanding, and particularly it’s connection with
              developing conditions. We do anonymized research with the data we
              collect that we ultimately aim to publish. For example, we know
              that ~30% of babies vaginally born and exclusively breastfed have
              a less ideal gut (which is surprisingly high) and we’re doing the
              next level of analysis to determine if it has to do with shorter
              labor / pushing times or other factors, like family history. By
              connecting the surveys with the microbiome results, we can further
              our understanding of the microbiome and share that with you to
              help optimize microbiome health.
            </Typography>
          </li>
        </ol>
      </div>
    </Modal>
  );
};
